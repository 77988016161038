import React, { useState, useEffect } from 'react';
import { Button, Typography, Box, Container } from '@mui/material';

function InstallPWA() {
    const [isInstallable, setIsInstallable] = useState(false);
    const [deferredPrompt, setDeferredPrompt] = useState(null);

    useEffect(() => {
        // Listen for the beforeinstallprompt event
        window.addEventListener(
            'beforeinstallprompt',
            handleBeforeInstallPrompt
        );

        return () => {
            // Clean up the event listener
            window.removeEventListener(
                'beforeinstallprompt',
                handleBeforeInstallPrompt
            );
        };
    }, []);

    const handleBeforeInstallPrompt = (e) => {
        // Prevent the mini-infobar from appearing on mobile
        e.preventDefault();
        // Stash the event so it can be triggered later
        setDeferredPrompt(e);
        // Update UI to notify the user they can install the PWA
        setIsInstallable(true);
    };

    const handleInstallClick = async () => {
        if (deferredPrompt) {
            // Show the install prompt
            deferredPrompt.prompt();
            // Wait for the user to respond to the prompt
            const { outcome } = await deferredPrompt.userChoice;
            if (outcome === 'accepted') {
                console.log('User accepted the PWA install prompt');
            } else {
                console.log('User dismissed the PWA install prompt');
            }
            // Reset the deferred prompt variable, it can only be used once
            setDeferredPrompt(null);
            setIsInstallable(false);
        }
    };

    return (
        <Container maxWidth="sm">
            <Box
                sx={{
                    textAlign: 'center',
                    padding: '2rem',
                    backgroundColor: '#f4f4f9',
                    borderRadius: '10px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    height: '400px',
                }}
            >
                <Typography variant="h4" gutterBottom>
                    Install Our App
                </Typography>
                <Typography variant="body1" paragraph>
                    Get quick access to our app by installing it on your device!
                </Typography>
                {isInstallable ? (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleInstallClick}
                        sx={{ mt: 2 }}
                    >
                        Install App
                    </Button>
                ) : (
                    <Typography variant="body2" color="textSecondary">
                        App is Already Installed !
                    </Typography>
                )}
            </Box>
        </Container>
    );
}

export default InstallPWA;
