import { useTheme } from '@mui/material/styles';
import Card from "@mui/material/Card"
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Divider from '@mui/material/Divider';
import Typography from "@mui/material/Typography"
import React from 'react'


export default function AboutCard({title, image}) {
  const theme = useTheme();
  return (

    <Card sx={{
      width: "100%", height: "100%", maxHeight: "300px", position: "relative", "&:hover .MuiCardContent-root": {
        opacity: 1

      },
      '&:hover .MuiDivider-root': {
        width: '50px',
      },
    }}>
      <CardMedia component={"img"} src={image} alt="img" sx={{ height: "100%", width: "100%", object: "cover" }} />

      <CardContent className='card-content' sx={{
        position: "absolute", width: "100%", height: "100%", top: 0, left: 0, objectFit: "cover", bgcolor: "rgba(0,0,0,0.5)", z: 20, opacity: 0, transition: "opacity 300ms ease-in-out", display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: "center"
      }}>
        <Typography gutterBottom variant='h5' sx={{ color: 'white' }}>{title}</Typography>
        <Divider orientation='horizontal' sx={{ width: "0px", height: "5px", bgcolor: theme.palette.primary.main, transition: "all 300ms ease-in-out" }}></Divider>

      </CardContent>


    </Card>





  )
}
