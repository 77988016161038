import React from 'react'
import Card from "@mui/material/Card"
import CardMedia from "@mui/material/CardMedia"
import Typography from "@mui/material/Typography"
import { useTheme } from '@emotion/react';
import Box from "@mui/material/Box"
import QuantitySelector from './QuantitySelector';


export default function CartItemMobile({item}) {
    const theme = useTheme();
  return (
    <Card sx={{ p: 2, justifyContent: 'space-between', display: "flex",width:"100%" }}>
    <Box sx={{ display: "flex", gap: 2 }}>
        <CardMedia
            component="img"
            image={item.details.image}
            alt={item.details.name}
            sx={{ width: 60, height: 60, borderRadius: 2 }}
        />
        <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="caption" sx={{ fontWeight: "800" }}>{item.details.name}</Typography>
            <Typography sx={{ fontWeight: "600", color: "gray" }} variant="caption">x {item.quantity} ({item.product_unit})</Typography>

        </Box>


    </Box>

    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
        <QuantitySelector item={item} />
        <Box sx={{ display: "flex", gap: 2, mt: 0.5 }}>
            <Typography variant='caption' sx={{ fontWeight: 500, color: item.details.discounted_price ? "gray" : theme.palette.primary.main, textDecoration: item.details.discounted_price ? 'line-through' : "none", fontWeight: 500 }}>₹ {item.details.price * item.quantity}</Typography>

            <Typography color="primary" sx={{ fontWeight: 500 }} variant='caption'>₹ {item.details.discounted_price * item.quantity}</Typography>

        </Box>


    </Box>







</Card>
  )
}
