import React from 'react'
import Drawer from "@mui/material/Drawer"
import Box from "@mui/material/Box"
import ProductDetail from './ProductDetail'
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Close"

export default function ProductDetailDrawer({ drawerOpen, toggleDrawer, product }) {
    return (
        <Drawer
            anchor={"bottom"}
            open={drawerOpen}
            onClose={toggleDrawer}
        >
            <Box>
                <IconButton
                    sx={{ position: 'absolute', top: 8, right: 8, boxShadow: 2, bgcolor: "background.default" }}
                    onClick={toggleDrawer}
                >
                    <CloseIcon fontSize='small' />
                </IconButton>
                <ProductDetail productData={product} />

            </Box>

        </Drawer>

    )
}
