import React from 'react'
import { useSelector } from 'react-redux'
import CartItem from './CartItem';
import Box from "@mui/material/Box"
import { useMediaQuery } from '@mui/material';
import CartItemMobile from './CartItemMobile';



export default function CartList() {
  const cart = useSelector(state => state.cart);
  const isSmallScreen = useMediaQuery(theme=>theme.breakpoints.down('sm'));
  console.log(cart.products)
  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: 'center' }}>
      {cart.products.map((item) => (
        isSmallScreen ? <CartItemMobile key={item.id} item={item}/> :<CartItem key={item.id} item={item} />
        
        
      ))}
    </Box>

  )
}




