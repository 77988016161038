import React, { useState } from 'react';
import  Typography  from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import Grid from "@mui/material/Grid"
import { useTheme } from '@mui/material/styles';
import Modal from "@mui/material/Modal"
import Rating from "@mui/material/Rating"
import Avatar from "@mui/material/Avatar"
import Divider from "@mui/material/Divider"
import FormControl from "@mui/material/FormControl"
import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import Star from "@mui/icons-material/Star"
import StarBorder from "@mui/icons-material/StarBorder"
import CloseIcon from "@mui/icons-material/Close"
import { useSelector } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/navigation';
import { Scrollbar, Navigation } from 'swiper/modules';

const initialReviews = [
    {
        rating: 5,
        comment: "msssstt 👍👍👍👍",
        user: "Tania",
        date: "16 Dec 2023, 10:45 AM",
        images: [
            'path/to/image1.jpg',
            'path/to/image2.jpg',
            'path/to/image3.jpg'
        ],
    },
];



const ReviewModal = ({ open, handleClose, images, review }) => {
    return (
        <Modal open={open} onClose={handleClose}>
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '70%',
                height: '70%',
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                outline: 'none',
            }}>
                <IconButton
                    sx={{ position: 'absolute', top: 8, right: 8 }}
                    onClick={handleClose}
                >
                    <CloseIcon />
                </IconButton>

                <Grid container spacing={2}>
                    <Grid item lg={8} sm={12}>
                        <Swiper
                            spaceBetween={50}
                            slidesPerView={1}
                            navigation
                            modules={[Scrollbar, Navigation]}
                            pagination={{ clickable: true }}
                        >
                            {images.map((image, index) => (
                                <SwiperSlide key={index}>
                                    <img src={image} alt={`slide-${index}`} style={{ width: '100%', height: 'auto' }} />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </Grid>

                    <Grid item lg={4} sm={12}>
                        {review && (
                            <Box>
                                <Typography variant="h6">Review Details</Typography>
                                <Rating value={review.rating} readOnly />
                                <Typography variant="body1">{review.comment}</Typography>
                                <Typography variant="body2">{review.user} | {review.date}</Typography>
                            </Box>
                        )}
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    );
};

export default function RatingReview() {

    const { user } = useSelector(state => state.auth);
    const theme = useTheme();
    const [reviews, setReviews] = useState(initialReviews);
    const [open, setOpen] = useState(false);
    const [openSwiper, setOpenSwiper] = useState(false);
    const [swiperImages, setSwiperImages] = useState([]);
    const [currentReview, setCurrentReview] = useState(null);
    const [newReview, setNewReview] = useState({
        rating: 0,
        comment: '',
        user: user.name,
        date: '',
        images: []
    });
    const [userHasReviewed, setUserHasReviewed] = useState(false);

    const averageRating = reviews.length > 0 ? reviews.reduce((acc, curr) => acc + curr.rating, 0) / reviews.length : 0;

    const ratingCounts = [0, 0, 0, 0, 0];
    reviews.forEach(review => {
        ratingCounts[review.rating - 1]++;
    });

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setNewReview({
            rating: 0,
            comment: '',
            user: user.data.name,
            date: '',
            images: []
        });
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setNewReview(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleRatingChange = (event, newValue) => {
        setNewReview(prevState => ({
            ...prevState,
            rating: newValue
        }));
    };

    const handleImageChange = (event) => {
        const files = event.target.files;
        const imageUrls = Array.from(files).map(file => URL.createObjectURL(file));
        setNewReview(prevState => ({
            ...prevState,
            images: imageUrls
        }));
    };

    const handleSubmit = () => {
        const currentDate = new Date();
        const formattedDate = `${currentDate.toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' })}, ${currentDate.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true })}`;
        const updatedReviews = [
            ...reviews,
            {
                ...newReview,
                date: formattedDate
            }
        ];
        setReviews(updatedReviews);
        setUserHasReviewed(true);
        handleClose();
    };

    const handleOpenSwiper = (review) => {
        setSwiperImages(review.images);
        setCurrentReview(review);
        setOpenSwiper(true);
    };

    const handleCloseSwiper = () => {
        setOpenSwiper(false);
        setSwiperImages([]);
        setCurrentReview(null);
    };

    return (
        <Box sx={{ width: '100%', bgcolor: theme.palette.background.default, p: 3 }}>
            <Typography variant="h6" gutterBottom>RATINGS</Typography>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Box>
                    <Box sx={{ display: 'flex', flexDirection: "column", alignItems: 'center', mb: 2 }}>
                        <Box display="flex">
                            <Typography variant="h4">{averageRating.toFixed(1)}</Typography>
                            <Rating value={averageRating} precision={0.1} readOnly size="large" sx={{ ml: 1 }} />
                        </Box>
                        <Typography variant="body1" sx={{ ml: 1 }}>Total Reviews: {reviews.length}</Typography>
                    </Box>
                </Box>
                <Divider orientation="vertical" variant="middle" flexItem sx={{ mx: 5 }} />
                <Box sx={{ display: 'flex', flexDirection: 'column', mb: 3 }}>
                    {[5, 4, 3, 2, 1].map((star, index) => (
                        <Box key={index} sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography variant="body1">{star}</Typography>
                            <Rating value={star} max={5} readOnly icon={<Star />} emptyIcon={<StarBorder />} size="small" sx={{ ml: 1, mr: 1 }} />
                            <Typography variant="body1">{ratingCounts[star - 1]}</Typography>
                        </Box>
                    ))}
                </Box>
            </Box>
            <Typography variant="h6" gutterBottom>Customer Photos ({reviews.reduce((acc, curr) => acc + curr.images.length, 0)})</Typography>

            <Grid container spacing={1} sx={{ mb: 3 }}>
                {[...reviews.flatMap(review => review.images)].slice(0, 3).map((photo, index) => (
                    <Grid item key={index}>
                        <Avatar
                            variant="rounded"
                            src={photo}
                            sx={{ width: 80, height: 80, cursor: 'pointer' }}
                        />
                    </Grid>
                ))}
                {reviews.reduce((acc, curr) => acc + curr.images.length, 0) > 3 && (
                    <Grid item>
                        <Avatar variant="rounded" sx={{ width: 80, height: 80, bgcolor: 'grey.300' }}>+{reviews.reduce((acc, curr) => acc + curr.images.length, 0) - 3}</Avatar>
                    </Grid>
                )}
            </Grid>

            <Typography variant="h6" gutterBottom>Customer Reviews ({reviews.length})</Typography>
            {reviews.map((review, index) => (
                <Box key={index} sx={{ mb: 3 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                        <Rating value={review.rating} readOnly size="small" />
                        <Typography variant="body2" sx={{ ml: 1 }}>{review.comment}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                        {review.images.map((img, idx) => (
                            <Avatar
                                key={idx}
                                variant="rounded"
                                src={img}
                                sx={{ width: 80, height: 80, cursor: 'pointer' }}
                                onClick={() => handleOpenSwiper(review)}
                            />
                        ))}
                    </Box>
                    <Typography variant="caption">{review.user} | {review.date}</Typography>
                </Box>
            ))}
            <Divider />
            <Typography variant="body2" sx={{ mt: 2 }}>View all {reviews.length} reviews</Typography>
            <Button
                variant="contained"
                color="primary"
                sx={{ mt: 2, color: "white", borderRadius: "1.2rem" }}
                onClick={handleOpen}
                disabled={userHasReviewed}
            >
                Add Review
            </Button>

            <Modal open={open} onClose={handleClose}>
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4
                }}>
                    <Typography variant="h6" gutterBottom>Add Review</Typography>
                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <Rating
                            name="rating"
                            value={newReview.rating}
                            onChange={handleRatingChange}
                            size='large'
                        />
                    </FormControl>
                    <TextField
                        fullWidth
                        label="Comment"
                        name="comment"
                        value={newReview.comment}
                        onChange={handleChange}
                        multiline
                        rows={4}
                        sx={{ mb: 2 }}
                    />

                    <input
                        accept="image/*"
                        style={{ display: 'none' }}
                        id="upload-images"
                        multiple
                        type="file"
                        onChange={handleImageChange}
                    />
                    <label htmlFor="upload-images">
                        <Button variant="contained" component="span" sx={{ mb: 2, color: "white", borderRadius: "1.2rem" }}>
                            Upload Images
                        </Button>
                    </label>
                    <Box sx={{ display: 'flex', gap: 1, mb: 2 }}>
                        {newReview.images.map((img, index) => (
                            <Avatar key={index} variant="rounded" src={img} sx={{ width: 80, height: 80 }} />
                        ))}
                    </Box>
                    <Button sx={{ color: "white", borderRadius: "1.2rem" }} variant="contained" color="primary" onClick={handleSubmit}>Submit</Button>
                </Box>
            </Modal>

            <ReviewModal open={openSwiper} handleClose={handleCloseSwiper} images={swiperImages} review={currentReview} />
        </Box>
    );
}
