import React, { useState, useEffect } from 'react';
import paymentService from '../../services/paymentService';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Button, IconButton } from '@mui/material';

function QRCodeDisplay() {
  const [svgContent, setSvgContent] = useState('');
  const [loading, setLoading] = useState(false);

  const fetchQR = async () => {
    try {
      setLoading(true);
      const svgData = await paymentService.getQR();
      setSvgContent(svgData);
    } catch (error) {
      console.error("Error fetching SVG:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchQR();
  }, []);

  return (
    <div>
      {loading ? (
        <p>Loading QR code...</p>
      ) : (
        <div dangerouslySetInnerHTML={{ __html: svgContent }} />
      )}
      <Button size='small' variant='outlined' color='primary' onClick={fetchQR} disabled={loading}>
        <RefreshIcon sx={{mr:1}}/>
        Refresh Qr
      </Button>
    </div>
  );
}

export default QRCodeDisplay;
