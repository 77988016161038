import React from 'react';
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import EditIcon from '@mui/icons-material/Edit';
import Typography from "@mui/material/Typography";
import {  useSelector } from 'react-redux';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";

export default function MyProfile() {
    const { user } = useSelector(state => state.auth);
    console.log("User:", user);

    const address = useSelector(state => state.address);

    const defaultAddress = address?.addresses?.find(address => address.is_default === 1);
    console.log(defaultAddress);

    if (!user) {
        return null;
    }

    return (
        <>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", py: 2 }}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    <Avatar sx={{ width: "100px", height: "100px" }} alt="profile" src={`https://api.dicebear.com/9.x/initials/svg?seed=${user.name}`} />
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography sx={{ mt: 1, fontWeight: 500 }} gutterBottom variant="body1">{user.name}</Typography>
                        <Typography variant='body2'>{user.email}</Typography>
                    </Box>
                </Box>
                <Button size="small" variant='contained' sx={{ color: "white", borderRadius: "1.2rem" }} startIcon={<EditIcon />}>
                    Edit
                </Button>
            </Box>

            <Divider flexItem orientation='horizontal' />

            <Box sx={{ mt: 5, py: 2 }}>
                <Typography variant="h6" gutterBottom>Personal Information</Typography>
                <Grid sx={{ mt: 3 }} rowGap={2} container>
                    <Grid md={6} xs={6} item >
                        <Box>
                            <Typography gutterBottom variant="body1" sx={{ fontWeight: 500 }}>First Name</Typography>
                            <Typography variant='body1'>{user.name.split(" ")[0]}</Typography>
                        </Box>
                    </Grid>
                    <Grid md={6} xs={6} item>
                        <Typography gutterBottom variant="body1" sx={{ fontWeight: 500 }}>Last Name</Typography>
                        <Typography variant='body1'>{user.name.split(" ")[1] || "NULL"}</Typography>
                    </Grid>
                    <Grid md={6} xs={6} item>
                        <Typography gutterBottom sx={{ fontWeight: 500 }} variant="body1">Email Address</Typography>
                        <Typography variant='body1'>{user.email}</Typography>
                    </Grid>
                    <Grid md={6} xs={6} item>
                        <Typography gutterBottom sx={{ fontWeight: 500 }} variant="body1">Phone Number</Typography>
                        <Typography variant='body1'>{user.phone_number || "NULL"}</Typography>
                    </Grid>
                </Grid>
            </Box>

            <Divider flexItem orientation='horizontal' />

            <Box sx={{ mt: 5, py: 2 }}>
                <Typography variant="h6" gutterBottom>Address</Typography>
                {defaultAddress ? (
                    <Grid sx={{ mt: 3 }} rowGap={2} container>
                        <Grid md={6} xs={6} item >
                            <Box>
                                <Typography gutterBottom variant="body1" sx={{ fontWeight: 500 }}>Country</Typography>
                                <Typography variant='body1'>{defaultAddress.country}</Typography>
                            </Box>
                        </Grid>
                        <Grid md={6} xs={6} item>
                            <Typography gutterBottom sx={{ fontWeight: 500 }} variant="body1">City Name</Typography>
                            <Typography variant='body1'>{defaultAddress.city}</Typography>
                        </Grid>
                        <Grid md={6} xs={6} item>
                            <Typography gutterBottom sx={{ fontWeight: 500 }} variant="body1">Postal Code</Typography>
                            <Typography variant='body1'>{defaultAddress.pincode}</Typography>
                        </Grid>
                    </Grid>
                ) : (
                    <Typography variant='body1'>No default address found.</Typography>
                )}
            </Box>
        </>
    );
}
