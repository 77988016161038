import React, { useState } from 'react';
import Box from "@mui/material/Box";
import PageTitle from '../components/core/PageTitle';
import Container from '@mui/material/Container';
import Grid from "@mui/material/Grid";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Button from "@mui/material/Button";
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import CartCheckout from '../components/cart/CartCheckout';
import QRCodeDisplay from '../components/payment/QRCodeDisplay';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import paymentService from '../services/paymentService'; 
import CloseIcon from "@mui/icons-material/Close"
import UploadIcon from '@mui/icons-material/UploadFile';

export default function Checkout() {
    const [selectedValue, setSelectedValue] = useState('a');
    const [note, setNote] = useState('Please keep exact change handy to help us serve you better');
    const [receiptImage, setReceiptImage] = useState(null);
    const [uploading, setUploading] = useState(false);
    const navigate = useNavigate();

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            setReceiptImage(URL.createObjectURL(file));
            await uploadReceipt(file);
        }
    };

    const uploadReceipt = async (file) => {
        setUploading(true);
        try {
            const response = await paymentService.uploadReceipt(file);
            toast.success("Receipt uploaded successfully!", {
                position:"top-center"
            });
            console.log("Receipt upload response:", response);
        } catch (error) {
            toast.error("Error uploading receipt. Please try again.");
            console.error("Upload error:", error);
        } finally {
            setUploading(false);
        }
    };

    

    const handleOrderConfirmed = () => {
        toast.success("Order confirmed successfully");
        navigate("/order-confirmed");
    };

    const handleRemoveImage = () => {
        setReceiptImage(null);
    };

    return (
        <Box sx={{ minHeight: "100vh" }}>
            <PageTitle title={"Checkout"} />
            <Container maxWidth="lg" sx={{ my: 5 }}>
                <Grid container columnSpacing={5} rowGap={5}>
                    <Grid item lg={6}>
                        <Typography variant="h5" sx={{ fontWeight: 500 }}>Select Payment Method</Typography>

                        <Box sx={{ my: 2, border: "1px solid lightGray", borderRadius: 2, overflow: "hidden" }}>
                        <Accordion>
                                <AccordionSummary
                                    expandIcon={<ArrowDropDownIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                >
                                    <Typography>Wallets</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                        malesuada lacus ex, sit amet blandit leo lobortis eget.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ArrowDropDownIcon />}
                                    aria-controls="panel2-content"
                                    id="panel2-header"
                                >
                                    <Typography>Add credit or debit cards</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                        malesuada lacus ex, sit amet blandit leo lobortis eget.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ArrowDropDownIcon />}
                                    aria-controls="panel3-content"
                                    id="panel3-header"
                                >
                                    <Typography>Netbanking</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                        malesuada lacus ex, sit amet blandit leo lobortis eget.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ArrowDropDownIcon />}
                                    aria-controls="panel4-content"
                                    id="panel4-header"
                                >
                                    <Typography>Add new UPI ID</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                        malesuada lacus ex, sit amet blandit leo lobortis eget.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ArrowDropDownIcon />}
                                    aria-controls="panel5-content"
                                    id="panel5-header"
                                >
                                    <Typography>Cash On Delivery</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography gutterBottom variant='body2'>
                                        {note}
                                    </Typography>
                                    <Button
                                        onClick={handleOrderConfirmed}
                                        size="small"
                                        variant='contained'
                                        color="primary"
                                        sx={{ color: "white", alignSelf: "start", mt: 2 }}
                                    >
                                        Place Order
                                    </Button>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
            <AccordionSummary
                expandIcon={<ArrowDropDownIcon />}
                aria-controls="panel6-content"
                id="panel6-header"
            >
                <Typography>Pay using QR code</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <QRCodeDisplay />
                <Box sx={{ mt: 2, display: 'flex', alignItems: 'center' }}>
                    <label htmlFor="upload-button">
                        <Button
                        size='small'
                            variant="contained"
                            color="primary"
                            startIcon={<UploadIcon />}
                            component="span"
                            sx={{ mr: 2,color:"white" }}
                        >
                            Upload Receipt
                        </Button>
                        <Input
                            id="upload-button"
                            type="file"
                            accept="image/*"
                            onChange={handleFileChange}
                            sx={{ display: 'none' }}
                        />
                    </label>
                    {uploading && <Typography variant="body2" sx={{ ml: 2 }}>Uploading...</Typography>}
                    {receiptImage && !uploading && (
                        <Box sx={{ ml: 2, position: 'relative' }}>
                            <img
                                src={receiptImage}
                                alt="Receipt"
                                style={{ maxWidth: "100%", height: "auto" }}
                            />
                            <IconButton
                                onClick={handleRemoveImage}
                                sx={{
                                    position: 'absolute',
                                    top: 0,
                                    right: 0,
                                    color: 'red',
                                    backgroundColor: 'white',
                                    '&:hover': {
                                        backgroundColor: '#f5f5f5'
                                    }
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    )}
                </Box>
            </AccordionDetails>
        </Accordion>
                        </Box>
                    </Grid>
                    <Grid item lg={6}>
                        <Typography variant='h5' sx={{ fontWeight: 500 }}>Order Details</Typography>
                        <CartCheckout />
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}
