import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Rating from "@mui/material/Rating";
import Button from "@mui/material/Button";
import CircularProgress from '@mui/material/CircularProgress';
import CardContent from "@mui/material/CardContent";
import { useTheme } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ProductModal from './ProductModal';
import { useDispatch, useSelector } from 'react-redux';
import { addItemToCart } from '../../redux/slices/cartSlice';
import useMediaQuery from '@mui/material/useMediaQuery';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
// import CartModal from '../cart/CartModal';
import ProductDetailDrawer from './ProductDetailDrawer';
// import CartDrawerMobile from '../cart/CartDrawerMobile';
import { useNavigate } from 'react-router-dom';
import { addWishlistItem } from '../../redux/slices/wishlistSlice';

const ProductCard = ({ productData, flexDirection = 'column', button = true }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
  const [isLoading, setIsLoading] = useState(false);
  const [detailOpen, setDetailOpen] = useState(false);
  // const [cartOpen, setCartOpen] = useState(false)

  const isMediumScreen = useMediaQuery(theme => theme.breakpoints.down("md"));




  const handleDetailOpen = () => setDetailOpen(true);
  const handleDetailClose = () => setDetailOpen(false);
  const navigate = useNavigate();


  const handleAddItem = async () => {
    if (isAuthenticated) {
      setIsLoading(true);
      await dispatch(addItemToCart(productData));
      setIsLoading(false);
      // setCartOpen(true);
    } else {
      navigate('/account/login');
    }

  };

  const handleAddToWishlist = async () => {
    if (isAuthenticated) {
      await dispatch(addWishlistItem(productData));
    } else {
      navigate('/account/login');
    }
  };

  // const handleCartOpen = () => setCartOpen(true);
  // const handleCartClose = () => { setCartOpen(false); }

  const { isAuthenticated } = useSelector(state => state.auth);



  return (
    <Box sx={{
      position: "relative",
      width: "100%",
      height: "100%",
      transition: "all 200ms ease-in-out",
      '&:hover .overlay': {
        opacity: 1,
        transform: 'scale(1)',
        transition: 'opacity 300ms ease-in-out, transform 300ms ease-in-out',

      }
    }}>
      <Card elevation={2} sx={{
        position: "relative",
        display: "flex",
        flexDirection: flexDirection,
        width: "100%",
        height: "100%",
     

      }}>
        <Box sx={{ position: "relative", flex: flexDirection === "column" ? 0 : 4 }}>
          <CardMedia
            component="img"
            loading='lazy'
            height={flexDirection === "row" ? "100%" : "150px"}
            image={productData.image || "/orange.png"}
            sx={{ objectFit: "cover" }}
            alt={productData.name || "Oranges"}
          />

          {/* overlay of icons */}
          <Box className="overlay" sx={{
            position: "absolute",
            bottom: "10px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            gap: 1,
            opacity: isSmallScreen ? 1 : 0,
            transform: isSmallScreen ? 'scale(1)' : 'scale(0)',
          }}>
            <IconButton onClick={handleDetailOpen} sx={{
              color: 'black', bgcolor: "white", boxShadow: 4, "&:hover": {
                bgcolor: theme.palette.primary.main, color: "white"
              }
            }}>
              <SearchIcon sx={{ fontSize: "1.2rem" }} />
            </IconButton>
            <IconButton onClick={handleAddToWishlist} sx={{
              color: 'black', bgcolor: "white", boxShadow: 4, "&:hover": {
                bgcolor: theme.palette.primary.main, color: "white"
              }
            }}>
              <FavoriteIcon sx={{ fontSize: "1.2rem" }} />
            </IconButton>
          </Box>

          {/* modal */}
          {
            !isMediumScreen ? (<ProductModal open={detailOpen} handleClose={handleDetailClose} productData={productData} />) : (<ProductDetailDrawer product={productData} drawerOpen={detailOpen} toggleDrawer={handleDetailClose} />)
          }

        </Box>

        <CardContent sx={{
          display: "flex",
          height:"100%",
          flexDirection: "column",
          alignItems: flexDirection === "column" ? "center" : "flex-start",
          gap: "0.2rem",
          flex: flexDirection === "column" ? 0 : 8,

        }}>
          <Typography variant="h7" fontWeight={"600"} component="div" sx={{ color: "gray" }}>
            {productData.name || "Oranges"}
          </Typography>

          <Box sx={{ display: "flex", gap: 2 }}>
            <Typography variant="body2" fontWeight={"600"} sx={{ whiteSpace: "nowrap", textDecoration: productData.discounted_price ? "line-through" : "none", color: productData.discounted_price ? "gray" : theme.palette.primary.main }}>
              ₹ {productData.price || "3.2"}
            </Typography>

            <Typography whiteSpace={"nowrap"} variant="h7" fontWeight={"600"} color={theme.palette.primary.main}>
              ₹ {productData.discounted_price || "3.2"}
            </Typography>

          </Box>

          {productData.description && flexDirection === "row" && (
            <Typography variant='body2'>
              {`${productData.description.split(" ").slice(0,12).join(" ")}...` || "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Adipisci iusto minus fugit, iste eius aspernatur inventore quos labore delectus ad."}
            </Typography>
          )}
          <Rating name="read-only" size='small' value={productData.rating || 3} readOnly />
        
          {button && (
        
          <Button
            onClick={handleAddItem}
            size="small"
            variant="contained"
            disabled={!productData.is_in_stock}
            sx={{
              backgroundColor: theme.palette.primary.main,
              color: "white",
              display:"flex",
              alignItems:"center",
              gap:0.5,
              mt:1
              
            }}
          >
            {!isLoading ? (
              <>
              <Typography variant='caption'>Add to cart</Typography>
              <ShoppingBagIcon sx={{fontSize:"1rem",mb:0.3}} />  
              </>
            ) : (
              <CircularProgress sx={{ color: "white" }} size={20} />
            )}
          </Button>
        
      )}



        </CardContent>


        {/* sold out label */}
        {!productData.is_in_stock && (
         <Box sx={{p:1,bgcolor:"red",color:"white",position:"absolute",top:0,left:0,boxShadow:5,fontSize:"0.8rem"}}>Sold Out</Box>
        )}
        
        {productData.discounted_price && (
         <Box sx={{p:1,bgcolor:"orange",color:"white",position:"absolute",top:0,right:0,boxShadow:5,fontSize:"0.8rem"}}>{(((productData.price-productData.discounted_price)/productData.price)*100).toFixed(0)} % Off</Box>
        )}
      </Card>

    

      {/* cart modal */}

      {/* NOT CONFIRMED */}
      {/* {
        isMediumScreen ? (<CartDrawerMobile item={productData} drawerOpen={cartOpen} toggleDrawer={handleCartClose} />) : (<CartModal open={cartOpen} handleClose={handleCartClose} item={productData} />)
      } */}
      {/* {
        isMediumScreen && <CartDrawerMobile item={productData} drawerOpen={cartOpen} toggleDrawer={handleCartClose} />
      } */}




    </Box>

  );
};

export default ProductCard;
