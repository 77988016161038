import React from 'react'
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import { Link } from 'react-router-dom'
import emptyCartImg from "../../assets/empty-cart.png"


const EmptyCart = ({onClose}) => {
    return (
      <Box sx={{width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection:"column" }}>
        
        <img src={emptyCartImg} alt="empty cart" style={{width:"400px"}}/>
        <Typography gutterBottom variant="h5" sx={{fontWeight:800}}>Your cart is empty !</Typography>
        <Typography variant="body1" sx={{fontWeight:500}}>Looks like you haven't added anything to your cart yet</Typography>
  
        <Link to="/shop">
        <Button onClick={onClose} size="small"  color="primary" variant='contained' sx={{borderRadius:"1.2rem", color:"white", boxShadow:4, mt:3}} >Continue Shopping</Button>
        </Link>
      
  
      </Box>
    )
  }


  export default EmptyCart;