import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import Container from '@mui/material/Container';
import ProductCard from '../product/ProductCard';
import { useTheme } from "@mui/material/styles";
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from "@mui/material/Box";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Divider, Typography } from '@mui/material';
import SectionTitle from '../core/SectionTitle';
import CategoryFilter from './CategoryFilter';

export default function BestSellers({ allProducts }) {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md')); 
  const [selectedCategoryId, setSelectedCategoryId] = useState(0); 

  const handleCategoryChange = (category) => {
      setSelectedCategoryId(category);
    };



    const filteredProducts = selectedCategoryId === 0 ? allProducts : allProducts.filter(item=>item.category_id === selectedCategoryId);

  return (
    <Container maxWidth="lg" sx={{ mt: 10 }}>
      <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center",flexWrap:"wrap"}}>

      <SectionTitle t1={"Best Sellers"} t2={"Shop Our Most Popular Items"}/>
      <CategoryFilter
        selectedCategoryId={selectedCategoryId}
        onCategoryChange={handleCategoryChange}
        />

      
      </Box>

      <Box sx={{ position: "relative", height: "300px" }}>
        <Swiper
          spaceBetween={15}
          navigation={isLargeScreen ? { 
            nextEl: '.swiper-button-next-our-products',
            prevEl: '.swiper-button-prev-our-products',
          } : false}
          modules={[Navigation]}
          className="mySwiper"
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            480: {
              slidesPerView: 2,
              spaceBetween: 15,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 25,
            },
          }}
          style={{ height: "100%", width: "100%", padding: 5 }}
        >
          {
            filteredProducts.slice(0, 10).map(item => (
              <SwiperSlide style={{ height: "100%" }} key={item.id}>
                <ProductCard productData={item} style={{ height: "100%" }} />
              </SwiperSlide>
            ))
          }
        </Swiper>

        {isLargeScreen && ( 
          <div style={{ position: "absolute", bottom: "-40px", right: "0px" }}>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: 5 }}>
              <div
                className="swiper-button-prev-our-products"
                style={{
                  backgroundColor: theme.palette.primary.main,
                  color: "white",
                  borderRadius: "50%",
                  width: "30px",
                  height: "30px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
              >
                <ArrowBackIcon />
              </div>

              <div
                className="swiper-button-next-our-products"
                style={{
                  backgroundColor: theme.palette.primary.main,
                  color: "white",
                  borderRadius: "50%",
                  width: "30px",
                  height: "30px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
              >
                <ArrowForwardIcon />
              </div>
            </div>
          </div>
        )}
      </Box>
    </Container>
  );
}
