import React, { useState, useCallback } from 'react';
import Box from "@mui/material/Box";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import debounce from 'lodash/debounce';
import { useDispatch } from 'react-redux';
import { updateItemQuantity } from '../../redux/slices/cartSlice';

export default function QuantitySelector({ item }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [localQuantity, setLocalQuantity] = useState(item.quantity);

  const debouncedUpdateQuantity = useCallback(
    debounce((newQuantity) => {
      dispatch(updateItemQuantity({ itemId: item.id, quantity: newQuantity, product_unit: item.product_unit }));
    }, 500),
    [dispatch, item.id, item.product_unit]
  );

  const handleIncreaseQuantity = () => {
    const newQuantity = localQuantity + 1;
    setLocalQuantity(newQuantity);
    debouncedUpdateQuantity(newQuantity);
  };

  const handleDecreaseQuantity = () => {
    if (localQuantity > 1) {
      const newQuantity = localQuantity - 1;
      setLocalQuantity(newQuantity);
      debouncedUpdateQuantity(newQuantity);
    }
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center", border: "0.2px solid lightgray", borderRadius: "0.3rem" }}>
      <Button
        sx={{
          maxWidth: '30px', minWidth: '30px', minHeight: '30px',
          borderRight: "1px solid lightGray", borderTopRightRadius: "0px", borderBottomRightRadius: "0px", "&:hover": { bgcolor: theme.palette.primary.main, color: theme.palette.text.primary }
        }}
        onClick={handleDecreaseQuantity}
      >
        -
      </Button>
      <TextField
        type="number"
        color='primary'
        value={localQuantity}
        inputProps={{ min: 1, style: { textAlign: 'center', fontSize: "0.8rem" } }}
        size="small"
        sx={{ width: "50px", "& fieldset": { border: 'none' } }}
        disabled
      />
      <Button
        sx={{
          maxWidth: '30px', minWidth: '30px', minHeight: '30px',
          borderLeft: "1px solid lightGray", borderTopLeftRadius: "0px", borderBottomLeftRadius: "0px", "&:hover": { bgcolor: theme.palette.primary.main, color: theme.palette.text.primary }
        }}
        onClick={handleIncreaseQuantity}
      >
        +
      </Button>
    </Box>
  );
}
