import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import CardMedia from "@mui/material/CardMedia"
import Divider from "@mui/material/Divider"
import Typography from "@mui/material/Typography"
import React from 'react'
import PersonIcon from '@mui/icons-material/Person';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import LabelIcon from '@mui/icons-material/Label';
import { Link } from 'react-router-dom';

export default function BlogCard({ blog }) {

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };
    return (
        <Card sx={{
            height: "100%", 
            transition: "all 200ms ease-in-out",
            '&:hover .image': {
                transform: "scale(1.1)",
            },
        }}>
            <Box sx={{ overflow: "hidden" }}> 
                <CardMedia
                    component="img"
                    height="200"
                    image={blog.image}
                    alt={blog.title}
                    className="image"
                    sx={{
                        transition: "transform 0.3s ease-in-out", 
                    }}
                />
            </Box>
            <CardContent sx={{textAlign:"left"}}>
                <Box flexDirection="row" display="flex" flexWrap="wrap" alignItems="center" justifyContent="left" sx={{ mb: 2 }}>
                    <Box display="flex" alignItems="center" gap={1} >
                        <PersonIcon color='primary' fontSize="xs" />
                        <Typography whiteSpace="nowrap" variant="body2" color="text.secondary">
                            {blog.author}
                        </Typography>
                    </Box>

                    <Divider sx={{ mx: 1 }} orientation="vertical" flexItem color="white" />

                    <Box display="flex" alignItems="center" gap={1} >
                        <CalendarTodayIcon color='primary' fontSize="xs" />
                        <Typography whiteSpace="nowrap" variant="body2" color="text.secondary">
                            {formatDate(blog.updated_at)}
                        </Typography>
                    </Box>
                    <Divider sx={{ mx: 1 }} orientation="vertical" flexItem color="white" />
                    <Box display="flex" alignItems="center" gap={1} >
                        <LabelIcon color='primary' fontSize="xs" />
                        <Typography whiteSpace="nowrap" variant="body2" color="text.secondary">
                            {blog.tags}
                        </Typography>
                    </Box>

                </Box>

                <Typography gutterBottom variant="h6" >
                    {blog.title}
                </Typography>
                <Typography gutterBottom variant="body1" sx={{ color: "gray" }} >
                    {blog.shortDesc}
                </Typography>

                <Link to={`/blog/${blog.title}`}>
                    <Button size="small" color='primary' variant='contained' sx={{ color: "white", mt: 2 }}>Read More</Button>
                </Link>

            </CardContent>
        </Card>
    )
}
