import React from 'react';
import Box from "@mui/material/Box"
import Typography from '@mui/material/Typography'
import Button from "@mui/material/Button"
import GroceryImage from '../../assets/Banner.jpg';
import { useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import Container from "@mui/material/Container"

const baseCloudinaryURL = process.env.REACT_APP_CLOUDINARY_BASE_URL;

const bannerImageURL = `${baseCloudinaryURL}/w_1920,h_1080,c_fill,q_auto,f_auto/v1722407340/vegingo/Banner_shz0zz.jpg`;

const SpecialDiscountBanner = () => {
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
  const theme = useTheme();
  return (
    <Box
      sx={{
        mt:5,
        position: "relative",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: "50vh",
        width: "100%",
        padding: '1rem',
        backgroundImage: `url(${bannerImageURL})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        overflow: 'hidden',
        p: 2,
        
      }}
    >

      <Container maxWidth="lg">
        <Box style={{ maxWidth: isSmallScreen ? "100%" : '50%', color: "black", position: "relative", zIndex: 10, textAlign: "center" }}>
          <Typography  sx={{ fontWeight:800 }} variant={isSmallScreen? 'h4':'h3'} gutterBottom>
            Special Discount For All Grocery Products
          </Typography>
          <Typography variant="body1">
            Turpis tincidunt id aliquet risus feugiat. Pretium vulputate sapien nec sagittis aliquam. Ac tortor vitae purus faucibus ornare suspendisse sed nisi. Amet risus nullam eget felis eget nunc lobortis mattis aliquam.
          </Typography>
          <Button variant="contained" style={{ marginTop: '1rem', backgroundColor: theme.palette.primary.main,color: "white" }}>Buy Now</Button>
        </Box>

      </Container>


      {/* overlay */}
      {/* <div style={{position:"absolute", width:"100%", height:"100%", top:0, left:0, backgroundColor:"rgba(0,0,0,0.4)", zIndex:5}}></div> */}
    </Box>
  );
};

export default SpecialDiscountBanner;
