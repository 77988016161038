import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import HomeIcon from '@mui/icons-material/Home';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch } from 'react-redux';
import { removeAddress, setDefaultAddress } from '../../redux/slices/addressSlice';
import { Typography, Chip } from '@mui/material';

const AddressItem = ({ address, onEdit }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();

  const handleMoreClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMoreClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    onEdit(address);
    handleMoreClose();
  };

  const handleDelete = () => {
    dispatch(removeAddress(address.id));
    handleMoreClose();
  };

  const handleMakeDefault = () => {
    dispatch(setDefaultAddress(address.id));
    handleMoreClose();
  };

  return (
    <ListItem divider>
      <ListItemAvatar>
        <Avatar src=''>
          <HomeIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          <>
            <Typography variant="body2" fontWeight={500}>{address.user_name}</Typography>
            <Typography variant='body2'>{address.building_name_area}, {address.landmark}</Typography>
          </>
        }
        secondary={
          <>
            <Typography variant='body2'>{address.city} - {address.pincode}</Typography>
            <Typography variant='body2'>{address.state}, {address.country}</Typography>
            <Typography variant='body2'>{address.phone_number}</Typography>
          </>
        }
      />
      {
        address.is_default === 1 && (
          <Chip 
            label="Default" 
            color="primary" 
            size="small" 
            sx={{ mr: 4,color:"white" }} 
          />
        )
      }
      <IconButton onClick={handleMoreClick} edge="end" aria-label="more">
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleMoreClose}>
        <MenuItem onClick={handleEdit}>Edit</MenuItem>
        <MenuItem onClick={handleDelete}>Delete</MenuItem>
        <MenuItem onClick={handleMakeDefault}>Make Default</MenuItem>
      </Menu>
    </ListItem>
  );
};

export default AddressItem;
