import { React, useState } from 'react';
import { addItemToCart } from '../../redux/slices/cartSlice';
import { useDispatch, useSelector } from 'react-redux';
import { addWishlistItem } from '../../redux/slices/wishlistSlice';
import ProductModal from '../product/ProductModal';
import CountdownTimer from '../product/CountdownTimer';
import { useTheme } from '@emotion/react';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import FavoriteIcon from '@mui/icons-material/Favorite';
import SearchIcon from '@mui/icons-material/Search';
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import CardMedia from "@mui/material/CardMedia"
import IconButton from "@mui/material/IconButton"
import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"
import { CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';




export default function FlashDealCard({ product }) {
    const [buttonLoading, setButtonLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const theme = useTheme();
    const { isAuthenticated } = useSelector(state => state.auth);
    const navigate = useNavigate();


    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleAddItem = async () => {
        if (isAuthenticated) {
            setButtonLoading(true);
            await dispatch(addItemToCart(product));
            setButtonLoading(false);

        }

        else {
            navigate("account/login")

        }


    };



    const handleAddToWishlist = async () => {
        if (isAuthenticated) {
            await dispatch(addWishlistItem(product));
        } else {
            navigate('/account/login');
        }
    };

    return (
        <Card sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
            boxShadow: "none",
            position: "relative"
        }}>
            <ProductModal open={open} handleClose={handleClose} productData={product} />



            <CardMedia
                component="img"
                height="170px"
                loading='lazy'
                image={product.image || "/orange.png"}
                sx={{ objectFit: "cover" }}
                alt={product.name || "Oranges"}
            />

            <CardContent sx={{ textAlign: "center" }}>



                <Typography variant='h7' sx={{ fontWeight: "600", color: "gray" }}>{product.name}</Typography>



                <Box sx={{ justifyContent: "center", alignItems: "center", display: "flex", gap: 2 }}>
                    <Typography variant='h7' sx={{ textDecoration: product.discounted_price ? "line-through" : "none", color: product.discounted_price ? "gray" : theme.palette.primary.main }}>₹ {product.price}</Typography>
                    {
                        product.discounted_price && <Typography color="primary" variant='h7' sx={{ fontWeight: "bold" }} >₹ {product.discounted_price}</Typography>
                    }

                </Box>



                <Box sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", gap: 2, width: "100%", mt: 2 }}>

                    <Paper onClick={handleAddItem} sx={{ bgcolor: theme.palette.primary.main, borderRadius: "50%" }}>
                        <IconButton disabled={!product.is_in_stock} size='small' >
                            {
                                buttonLoading ? <CircularProgress size={20} sx={{ color: "white" }} /> : <ShoppingBagIcon sx={{ color: "white" }} />
                            }

                        </IconButton>

                    </Paper>

                    <Paper sx={{ bgcolor: theme.palette.primary.main, borderRadius: "50%" }}>
                        <IconButton size='small' onClick={handleOpen}>
                            <SearchIcon sx={{ color: "white" }} />
                        </IconButton>

                    </Paper>
                    <Paper onClick={() => handleAddToWishlist(product)} sx={{ bgcolor: theme.palette.primary.main, borderRadius: "50%" }}>
                        <IconButton size='small'>
                            <FavoriteIcon sx={{ color: "white" }} />
                        </IconButton>

                    </Paper>

                </Box>


                <Box sx={{ position: "absolute", top: "50%",left:10, transform: "translateY(-50%)" }}>
                    <CountdownTimer days={3} />
                </Box>


            </CardContent>



        </Card>
    )
}
