import React from 'react'
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import useMediaQuery from '@mui/material/useMediaQuery'
import { Link, useLocation } from 'react-router-dom'
import { useTheme } from '@mui/material/styles';

export default function PageTitle({ title }) {
  const theme = useTheme();
  // const location = useLocation();
  // const decodedPath = decodeURIComponent(location.pathname);
  // const path = decodedPath.split("/");

  const isSmallScreen = useMediaQuery((theme)=>theme.breakpoints.down("sm"));


  return (
    <Box sx={{ bgcolor: theme.palette.primary.main, textAlign: "center", py: "0.5rem" }}>


      <Typography variant={isSmallScreen ? "h3" : "h2"} sx={{ color: 'white', fontFamily:"Playfair Display" }}>{title}</Typography>

      {/* <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "0.5rem" }}>
        <Link to="/">
          <Typography variant='body2' sx={{ color: 'white', fontWeight:500, textTransform:"uppercase" }}>Home /</Typography>
        </Link>
        <Typography variant='body2' sx={{  color: 'white',fontWeight:500,textTransform:"uppercase" }}>{path[1]} / {path[2]}</Typography>

      </div> */}

    </Box>
  )
}
