import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import Container from '@mui/material/Container';
import ProductCard from '../product/ProductCard';
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Grid from "@mui/material/Grid";
import Button from '@mui/material/Button';
import Divider from "@mui/material/Divider";
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import CategoryFilter from './CategoryFilter';

export default function OurProducts({ allProducts }) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [selectedCategoryId, setSelectedCategoryId] = useState(0); 

  const handleCategoryChange = (category) => {
      setSelectedCategoryId(category);
    };



    const filteredProducts = selectedCategoryId === 0 ? allProducts : allProducts.filter(item=>item.category_id === selectedCategoryId);

  return (
    <Container maxWidth="lg" sx={{ mt: 10 }}>
      <Divider variant='fullWidth' sx={{ borderWidth: 1, mb: 1 }} ><CategoryFilter
        selectedCategoryId={selectedCategoryId}
        onCategoryChange={handleCategoryChange}/></Divider>

      <Grid sx={{ height: "100%" }} container columnSpacing={2} rowGap={2}>
        <Grid item md={3} xs={12}>
          <Box sx={{ height: "100%", width: "100%", position: "relative", minHeight: "300px" }}>
            <img style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", objectFit: "cover" }} src='https://t4.ftcdn.net/jpg/03/20/39/89/360_F_320398931_CO8r6ymeSFqeoY1cE6P8dbSGRYiAYj4a.jpg' alt='our products' />

            <Box sx={{ position: "absolute", top: 0, left: 0, bgcolor: "rgba(0,0,0,0.6)", color: "white", width: "100%", height: "100%", minHeight: "300px", p: 2 }}>
              <Typography variant='h4' sx={{fontFamily:"Playfair Display",fontWeight:600}}>Our Products</Typography>
              <Divider sx={{ borderColor: "white" }} flexItem />
              <Typography sx={{mt:1}} variant='body2'>Explore Our Wide Range of Fresh and Quality Products</Typography>

              <Link to="/shop">
                <Button variant='contained' size='small' sx={{ mt: 3, color: "white" }}>View All</Button>
              </Link>

              {!isSmallScreen && ( 
                <div style={{ position: "absolute", bottom: "20px", left: "20px" }}>
                  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: 5 }}>
                    <div
                      className="swiper-button-our-products"
                      style={{
                        backgroundColor: theme.palette.primary.main,
                        color: "white",
                        borderRadius: "50%",
                        width: "30px",
                        height: "30px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                    >
                      <ArrowBackIcon />
                    </div>

                    <div
                      className="swiper-button-next-best-sellers"
                      style={{
                        backgroundColor: theme.palette.primary.main,
                        color: "white",
                        borderRadius: "50%",
                        width: "30px",
                        height: "30px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                    >
                      <ArrowForwardIcon />
                    </div>
                  </div>
                </div>
              )}
            </Box>
          </Box>
        </Grid>

        <Grid item md={9} xs={12} sx={{ textAlign: "center" }}>
        
          <Box sx={{ position: "relative", height: "300px",mt:1 }}>
            <Swiper
              spaceBetween={15}
              navigation={isSmallScreen ? false : { 
                nextEl: '.swiper-button-next-best-sellers',
                prevEl: '.swiper-button-our-products',
              }}
              modules={[Navigation]}
              className="mySwiper"
              breakpoints={{
                320: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                480: {
                  slidesPerView: 2,
                  spaceBetween: 15,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 25,
                },
              }}
              style={{ height: "100%", width: "100%", padding: 5 }}
            >
              {filteredProducts.slice(0, 10).map(item => (
                <SwiperSlide style={{ height: "100%" }} key={item.id}>
                  <ProductCard productData={item} style={{ height: "100%" }} /> 
                </SwiperSlide>
              ))}
            </Swiper>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
