import React, { useState } from 'react';
import Box from "@mui/material/Box"
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import List from "@mui/material/List"
import Checkbox from "@mui/material/Checkbox"
import ListItem from '@mui/material/ListItem';
import Collapse from "@mui/material/Collapse"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useTheme } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import useMediaQuery from '@mui/material/useMediaQuery';

const filterOptions = {
    availability: [
        { label: 'In Stock', value: 1 },
        { label: 'Out of Stock', value: 0 }
    ],
    price: [
        { label: 'Less than Rs 20', value: 'under20' },
        { label: 'Rs 21 to Rs 50', value: '21to50' },
        { label: 'Rs 51 to Rs 100', value: '51to100' },
        { label: 'Rs 101 to Rs 200', value: '101to200' },
        { label: 'Rs 201 to Rs 500', value: '201to500' },
        { label: 'More than Rs 500', value: 'over500' }
    ],
    type: [
        { label: 'Vegetables', value: 1 || 2 },
        { label: 'Fruits', value: 3 }
    ],
    moreFilters: [
        { label: 'Organic', value: 'organic' },
        { label: 'Non-GMO', value: 'nonGMO' }
    ],
    brand: [
        { label: 'Brand A', value: 'brandA' },
        { label: 'Brand B', value: 'brandB' }
    ]
};

const filterMapping = {
    availability: 'is_in_stock',
    type: 'category_id',
    price: 'price',
    moreFilters: 'moreFilters',
    brand: 'brand',
    size: 'size'
};

const Filters = ({ selectedFilters, setSelectedFilters }) => {
    const [open, setOpen] = useState({
        availability: false,
        price: false,
        type: false,
        moreFilters: false,
        brand: false,
        size: false
    });

    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));

    const theme = useTheme();

    const handleClick = (section) => {
        setOpen(prevState => ({ ...prevState, [section]: !prevState[section] }));
    };

    const handleFilterChange = (section, value) => {
        const filterKey = filterMapping[section];
        if (section === 'price') {
            setSelectedFilters(prevState => ({
                ...prevState,
                [filterKey]: [value]
            }));
        } else {
            setSelectedFilters(prevState => {
                const sectionFilters = prevState[filterKey];
                if (sectionFilters.includes(value)) {
                    return {
                        ...prevState,
                        [filterKey]: sectionFilters.filter(filterValue => filterValue !== value)
                    };
                } else {
                    return {
                        ...prevState,
                        [filterKey]: [...sectionFilters, value]
                    };
                }
            });
        }
    };

    return (
        <Box>
            {Object.keys(filterOptions).map(section => (
                <Box key={section} sx={{ marginBottom: 2 }}>
                    <Typography
                        variant={{ xs: "body1", lg: "h6" }}
                        onClick={() => handleClick(section)}
                        sx={{
                            cursor: 'pointer',
                            display: 'flex',
                            justifyContent: 'space-between',
                            color: isSmallScreen ? "black" : "white",
                            bgcolor: isSmallScreen ? "none" : theme.palette.primary.main,
                            borderRadius: '0.3rem',
                            p: 1
                        }}
                    >
                        {section.charAt(0).toUpperCase() + section.slice(1)}
                        {open[section] ? <ExpandLess /> : <ExpandMore />}
                    </Typography>
                    <Collapse in={open[section]} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {section === 'price' ? (
                                <ListItem>
                                    <FormControl component="fieldset">
                                        <RadioGroup value={selectedFilters.price[0] || ''} onChange={(e) => handleFilterChange(section, e.target.value)}>
                                            {filterOptions.price.map(option => (
                                                <FormControlLabel key={option.value} value={option.value} control={<Radio />} label={option.label} />
                                            ))}
                                        </RadioGroup>
                                    </FormControl>
                                </ListItem>
                            ) : (
                                filterOptions[section].map(option => (
                                    <ListItem key={option.value}>
                                        <FormControl component="fieldset">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={selectedFilters[filterMapping[section]].includes(option.value)}
                                                        onChange={() => handleFilterChange(section, option.value)}
                                                    />
                                                }
                                                label={option.label}
                                                value={option.label}
                                            />
                                        </FormControl>
                                    </ListItem>
                                ))
                            )}
                        </List>
                    </Collapse>
                    <Divider flexItem orientation='horizontal'></Divider>
                </Box>
            ))}
        </Box>
    );
};

export default Filters;
