// src/CommentBox.js
import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Avatar from "@mui/material/Avatar"
import TextField from "@mui/material/TextField"
import Button from "@mui/material/Button"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemAvatar from "@mui/material/ListItemAvatar"
import ListItemText from "@mui/material/ListItemText"
import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import { useSelector } from 'react-redux';

const CommentBox = () => {
    const { user } = useSelector(state => state.auth);
    const [comment, setComment] = useState('');
    const [comments, setComments] = useState([
        { name: 'Arpit Singh', text: 'This is a sample comment.', date: 'April 26, 2024' },
        { name: 'John Doe', text: 'This is a sample comment.', date: 'March 3, 2022' },
        { name: 'Drake', text: 'This is a sample comment.', date: 'February 17, 2021' }
    ]);
    const theme = useTheme();



    const handleCommentChange = (event) => {
        setComment(event.target.value);
    };

    const handleCommentSubmit = () => {
        const currentDate = new Date();
        const formattedDate = `${currentDate.toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' })}, ${currentDate.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true })}`;
        if (comment.trim()) {
            const newComment = {
                name: user.data.name,
                text: comment,
                date: formattedDate
            };
            setComments([...comments, newComment]);
            setComment('');
        }
    };

    return (

        <Paper elevation={2} sx={{ p: 2, marginTop: '20px' }}>
            <Typography variant="h6">{comments.length} Comments</Typography>
            <List className='scroll' sx={{
                maxHeight: "300px", overflow: "auto", "&::-webkit-scrollbar-thumb": {
                    background: theme.palette.primary.main
                }
            }}>
                {comments.map((comment, index) => (
                    <ListItem key={index} style={{ borderBottom: '1px solid #ccc' }}>
                        <ListItemAvatar>
                            <Avatar>{comment.name.charAt(0)}</Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={comment.name}
                            secondary={`${comment.date} - ${comment.text}`}
                        />
                    </ListItem>
                ))}
            </List>

            <Box sx={{ mt: 4 }}>

                <TextField
                    label="Write a comment..."
                    required
                    multiline
                    rows={4}
                    variant="outlined"
                    value={comment}
                    onChange={handleCommentChange}
                    fullWidth
                    margin="normal"
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleCommentSubmit}
                    style={{ marginTop: '10px', color: "white", borderRadius: "1.2rem" }}
                >
                    Submit
                </Button>

            </Box>


        </Paper>
    );
};

export default CommentBox;
