import * as React from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import RatingReview from './RatingReview';

const TabPanel = ({ value, index, children }) => (
  <div  hidden={value !== index}>
    {value === index && (
      <Box sx={{ p: 3, border: "1px solid lightgray", minHeight: "600px" }}>
        <Typography>{children}</Typography>
      </Box>
    )}
  </div>
);

export default function CenteredTabs() {
  const [value, setValue] = React.useState(0);
  const theme = useTheme();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%', bgcolor: 'background.paper', my: 10 }}>
      <Tabs
        TabIndicatorProps={{
          sx: {
            bgcolor: theme.palette.primary.main,
            color: "red",
          },

        }}
        value={value} onChange={handleChange} centered>
        <Tab label="Description" />
        <Tab label="Shipping & Returns" />
        <Tab label="Reviews" />
      </Tabs>
      <TabPanel  value={value} index={0}>
        <Typography>Lorem ipsum dolor sit amet consectetur adipisicing elit. Deleniti eligendi assumenda cumque amet doloribus minus. Nihil consectetur maiores tenetur at, excepturi officiis molestiae asperiores non, ullam laudantium labore. Illum labore voluptatem nulla, ad dicta quibusdam culpa voluptatibus omnis recusandae dolore saepe accusamus ea minus debitis quisquam. Animi fugit eligendi sapiente minima asperiores aut mollitia. Porro nostrum minima deserunt ratione numquam consequatur perspiciatis quam rem voluptatem, ipsum unde natus dignissimos esse ipsa impedit dicta a placeat accusamus adipisci! Aperiam deserunt dolorum cupiditate placeat excepturi molestias eaque illum quo explicabo id! Veritatis, dolorem unde quos ex quis ipsum sequi itaque esse nulla.</Typography>
      </TabPanel>

      <TabPanel value={value} index={1}>
        <Typography>Item Two Content</Typography>
      </TabPanel>

      <TabPanel value={value} index={2}>
        <Typography>
          <RatingReview/>
        </Typography>
      </TabPanel>
    </Box>
  );
}
