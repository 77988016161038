import React, { useState } from 'react';
import AppBar from "@mui/material/AppBar";
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Slide from "@mui/material/Slide";
import MenuBar from './MenuBar';
import useMediaQuery from '@mui/material/useMediaQuery';
import TopBar from "./TopBar";
import MobileNavbar from './MobileNavbar';
import { useSelector } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@emotion/react';
import Box from "@mui/material/Box"

const Navbar = () => {
  const isMediumScreen = useMediaQuery(theme => theme.breakpoints.down("md"));
  const { isAuthenticated } = useSelector((state) => state.auth);
  const trigger = useScrollTrigger();
     

  return (
    <>
      {!isMediumScreen && <TopBar />}

      <Slide appear={false} direction="down" in={!trigger}>
        <AppBar
          position='sticky'
          sx={{ transition: 'all 0.3s ease-in-out', top: 0, left: 0, bgcolor: 'background.default' }}

        >
          <Toolbar>
            <MenuBar />


          </Toolbar>
        </AppBar>
      </Slide>


      {/* Mobile navigation for small screens */}
      {isMediumScreen && isAuthenticated && <MobileNavbar />}
    </>
  );
};

export default Navbar;
