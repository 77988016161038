import React from 'react'
import Hero from '../components/home/Hero';
import Testimonials from '../components/home/Testimonials';
import SpecialProducts from '../components/home/SpecialProducts';
import Category from '../components/home/Category';
import SpecialDiscountBanner from '../components/home/SpecialDiscountBanner';
import LatestNews from '../components/home/LatestNews';
import Subscribe from '../components/home/Subscribe';
import ProductPolicy from '../components/home/ProductPolicy';
import useAllData from '../hooks/useAllData';
import Loading from '../components/core/Loading';
import api from '../apiCollection';
import Error from '../components/core/Error';
import FreshFoodBanner from "../components/home/FreshFoodBanner"
import { useTheme } from '@mui/material/styles';
import OurProducts from '../components/home/OurProducts';
import NewProducts from '../components/home/NewProducts';
import BestSeller from '../components/home/BestSeller';

export default function Home() {
  const { allData, loading, error } = useAllData(api.products.getAll);
  const theme = useTheme();


  if (loading) {
    return <Loading />;
  }

  if (error) {
    console.log(error)
    return <Error code={error.request.status} />;
  }


  const specialProducts = allData.filter(product => product.best_seller);



  return (
    <>
      {/* <Box sx={{position:"relative"}}> */}

      <Hero />
      {/* <Box sx={{position:{xs:"relative",md:"absolute"},bottom:0, width:"100%", display:"flex",py:5,justifyContent:"center",alignItems:"center",zIndex:600, transform:{md:"translateY(50%)",xs:"translateY(0%)"}}}>

      <ProductPolicy />
      </Box> */}
      {/* </Box> */}      
      <Category />
      <NewProducts allProducts={allData}/>
      <BestSeller allProducts={allData}/>
      <OurProducts allProducts={allData} />
      {/* <SpecialDiscountBanner/> */}
      <FreshFoodBanner/>
      <SpecialProducts specialProducts={specialProducts} />
      <Testimonials />
      <LatestNews />
      {/* <Subscribe /> */}
    </>
  )
}
