import React from 'react';
import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"
import BlogCard from '../blog/BlogCard';
import SectionTitle from '../core/SectionTitle';
import useApiFetch from '../../hooks/useApiFetch';
import Loading from '../core/Loading';
import { api } from '../../apiCollection';
import Error from '../core/Error';
import { Typography } from '@mui/material';
import {useTheme} from "@mui/material/styles"


const LatestNews = () => {
  const allBlogsUrl = api.blog.getAll;
  const theme = useTheme();
  const { data, loading, error } = useApiFetch(allBlogsUrl);
  if (loading) {
    return <Loading/>;
  }

  if (error) {
    return <Error code={error.request.status}/>
  }
  return (
    <Container sx={{ mt: 5}}>
    <SectionTitle t1="Latest News" t2={"Stay Updated with Our Latest Updates"}/>
        <Grid sx={{mt:2}} columnSpacing={5} rowGap={5} container>
          {data.data.slice(0,6).map(blog => (
            <Grid key={blog.id} item xs={12} sm={6} md={4}>
              <BlogCard blog={blog} />
            </Grid>
          ))}
        </Grid>
    </Container>
  );
};

export default LatestNews;
