export const baseurl =
    // process.env.REACT_APP_API_BASE_URL || 'http://localhost:8000/api/v1';
// export const baseurl = "https://panel.vegingo.com/api/v1";
process.env.REACT_APP_API_BASE_URL || 'https://vegingo.com/api/v1';

export const api = {
    auth: {
        login: `${baseurl}/auth/client/signIn`,
        signUp: `${baseurl}/auth/client/signUp`,
    },

    google: {
        redirect: `${baseurl}/auth/login/google`,
        callback: `${baseurl}/auth/login/google/callback`,
    },

    products: {
        getAll: `${baseurl}/products`,
        getByName: `${baseurl}/products/searchProductsByName`,
        getById: `${baseurl}/products/getProductById`,
    },

    cart: {
        getItems: `${baseurl}/cart/getCartItems`,
        addItem: `${baseurl}/cart/addItemToCart`,
        deleteItem: `${baseurl}/cart/deleteCartItem`,
        updateItem: `${baseurl}/cart/updateCartItem`,
    },
    wishlist: {
        getAll: `${baseurl}/wishlist/get_wishlist`,
        addItem: `${baseurl}/wishlist/store_wish`,
        deleteItem: `${baseurl}/wishlist/delete_wish`,
    },

    blog: {
        getAll: `${baseurl}/blog`,
    },

    address: {
        getAddress: `${baseurl}/address/get_address`,
        addAddress: `${baseurl}/address/store_address`,
        updateAddress: `${baseurl}/address/update_address`,
        deleteAddress: `${baseurl}/address/delete_address`,
    },

    order: {
        recentOrders: `${baseurl}/order/get_recent_orderItems`,
        orderHistory: `${baseurl}/order/get_orderHistory`,
        createOrderFromCart: `${baseurl}/order/create_OrderFromCart`,
        createOrderFromProduct: `${baseurl}/order/create_OrderFromProduct`,
    },

    payment: {
        getQR: `${baseurl}/payment/qr`,
        uploadReceipt: `${baseurl}/payment/uploadReceipt`,
    },

    contact: `${baseurl}/contact-us`,
    newsletter: `${baseurl}/subscribe`,

    pincodeApi: `${baseurl}/api/pincode`,
};

export default api;
