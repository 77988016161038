import { useTheme } from '@mui/material/styles';
import Box from "@mui/material/Box"
import Typography from '@mui/material/Typography';
import React from 'react'
import useMediaQuery from '@mui/material/useMediaQuery';

export default function SectionTitle({ t1, t2 }) {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: "max-content", mb: 2 }}>
            <Typography color={"primary"} variant={isSmallScreen?'h7':'h6'}>~ {t2}</Typography>
            <Typography variant={isSmallScreen?'h5':'h4'} sx={{ fontFamily: "Playfair Display", fontWeight:600 }}>
                {t1}

            </Typography>

    
        </Box>


    )
}
