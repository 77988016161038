import Button from "@mui/material/Button"
import IconButton from "@mui/material/IconButton"
import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import useMediaQuery from "@mui/material/useMediaQuery"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch } from 'react-redux';
import { deleteWishlistItem } from '../../redux/slices/wishlistSlice';
import { useTheme } from '@mui/material';
import { addItemToCart, removeItemFromCart } from "../../redux/slices/cartSlice"
import SearchIcon from "@mui/icons-material/Search"

export default function WishlistCard({ product }) {
  const dispatch = useDispatch();
  console.log("Product data", product)
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));


  const handleRemoveFromWishlist = async () => {
    await dispatch(deleteWishlistItem(product.id));


  };

  const handleMoveToCart = async () => {
    await dispatch(addItemToCart(product.details));
    // dispatch(deleteWishlistItem(product.id))
  }
  const theme = useTheme();

  return (
    <TableRow>

      {isSmallScreen ? (
        <TableCell colSpan={4}>
          <Box sx={{ display: "flex", gap: 2 }}>
            <Box sx={{ height: "100px", width: "150px" }}  >
              <img src={product.details.image} style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: 4 }} alt={product.details.name} />
            </Box>

            <Box sx={{ width: "100%" }}>
              <Typography variant='body1' sx={{ fontWeight: "bold" }}>{product.details.name}</Typography>
              <Typography variant='body2' sx={{ fontWeight: "bold", color: "GrayText" }}>₹ {product.details.discounted_price}</Typography>

              <Box sx={{ display: "flex", mt: 2, gap: 1, justifyContent: "space-between", width: "100%" }}>
                <Button
                  variant="contained"
                  onClick={handleMoveToCart}
                  size="small"
                  sx={{
                    backgroundColor: theme.palette.primary.main,
                    color: "white"
                  }}
                >
                  Move to cart
                </Button>
                <IconButton onClick={handleRemoveFromWishlist} >
                  <DeleteIcon sx={{ fontSize: "1.2rem" }} />
                </IconButton>

              </Box>


            </Box>


          </Box>

        </TableCell>
      ) : (
        <>
          <TableCell align="center" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img src={product.details.image} alt={product.details.name} width="100px" style={{borderRadius:4}} />
          </TableCell>
          <TableCell align="center">{product.details.name}</TableCell>
          <TableCell align="center">₹ {product.details.discounted_price}</TableCell>
          <TableCell align="center">
            <Button
              onClick={handleMoveToCart}
              variant="contained"
              size="small"
              sx={{ backgroundColor: theme.palette.primary.main, color: "white" }}
            >
              Move to cart
            </Button>
          </TableCell>
          <TableCell align="center">
            <IconButton onClick={handleRemoveFromWishlist} >
              <DeleteIcon sx={{ fontSize: "1.2rem" }} />
            </IconButton>
          </TableCell>
        </>
      )}
    </TableRow>
  );
}
