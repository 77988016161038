import React from 'react';
import { Box, Typography, List, ListItem, Divider, Button, IconButton } from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CloseIcon from "@mui/icons-material/Close"

const orderItems = [
    {
        name: 'White Rolling Paper (Small) - Stash Pro',
        quantity: '1 pack (40 pieces)',
        price: '₹45'
    },
    {
        name: 'Brown Rolling Paper (King Size) - Stash Pro',
        quantity: '1 unit (32 pieces)',
        price: '₹50'
    },
    {
        name: 'Ultimate Rolling Paper with Filter Tips & Crushing Tray (King Size, Unbleached) - Mozo',
        quantity: '1 pack (32 pieces)',
        price: '₹90'
    },
    {
        name: 'Brown Rolling Paper Cones - Stash Pro',
        quantity: '6 pieces',
        price: '₹90'
    }
];

const OrderDetails = ({onClose}) => {
    return (
        <Box sx={{ width: "100%", padding: '20px' }}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <IconButton onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </Box>
            <Typography variant="h5" gutterBottom>
                4 items in this order
            </Typography>

            <List>
                {orderItems.map((item, index) => (
                    <Box key={index}>
                        <ListItem sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Box>
                                <Typography variant="subtitle1">{item.name}</Typography>
                                <Typography variant="body2" color="textSecondary">{item.quantity}</Typography>
                            </Box>
                            <Typography variant="subtitle1">{item.price}</Typography>
                        </ListItem>
                        {index < orderItems.length - 1 && <Divider />}
                    </Box>
                ))}
            </List>

            <Box mt={3}>
                <Typography variant="h6" gutterBottom>
                    Order details
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    Order ID: ORD040698698
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    Payment: Pay on Delivery
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    Deliver to: Arpit Singh, ED-105, Sector Q, Aligani, Lucknow
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    Order placed on: Sun, 25 Aug'24, 12:05 PM
                </Typography>
            </Box>

            <Box mt={3} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="body2">Need help with your order?</Typography>
                <Button variant="contained" color="primary" startIcon={<ShoppingCartIcon />}>
                    Chat with us
                </Button>
            </Box>
        </Box>
    );
};

export default OrderDetails;
