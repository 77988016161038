import React  from 'react'
import Box from "@mui/material/Box"
import CartItemMobile from './CartItemMobile'

import { useSelector } from 'react-redux';


export default function CartListMobile() {
    const cart = useSelector(state => state.cart);
   
    return (
        <Box sx={{display:"flex", flexDirection:"column", gap:1}}>
            {
                cart.products.map((item) => (
                    <CartItemMobile key={item.id} item={item} />


                ))
            }

        </Box>
    )
}
