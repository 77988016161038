import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import wishlistService from "../../services/wishlistService";
import { toast } from "react-toastify";
import axios from "axios";
import api from "../../apiCollection";

const initialState = {
  products: [],
  total: 0,
  status: "idle",
  error: null,
};


const axiosInstance = axios.create({
  maxRedirects: 5,
});


export const fetchProductDetails = async (productId) => {
  const fetchDetails = async () => {
    try {
      const response = await axiosInstance.get(
        `${api.products.getById}/${productId}`
      );
      return response.data.data;
    } catch (error) {
      if (error.response && error.response.status === 429) {
        await new Promise((resolve) => setTimeout(resolve, 2000)); 
        return fetchDetails();
      }
      throw error;
    }
  };
  return fetchDetails();
};


export const fetchWishlistItems = createAsyncThunk(
  "wishlist/fetchWishlistItems",
  async (_, { rejectWithValue }) => {
    try {
      const response = await wishlistService.getWishlistItems();
      const allWishlistItems = response.data;

      const wishlistItemsWithDetails = await Promise.all(
        allWishlistItems.map(async (item) => {
          try {
            const details = await fetchProductDetails(item.product_id);
            return { ...item, details };
          } catch (error) {
            toast.error(
              `Failed to fetch product details for ${item.product_id}`,
              {
                position: "top-center",
              }
            );
            throw error;
          }
        })
      );

      return {
        items: wishlistItemsWithDetails,
        total: wishlistItemsWithDetails.length,
      };
    } catch (error) {
      console.error("Error in fetchWishlistItems thunk:", error);
      toast.error("Failed to fetch wishlist items", {
        position: "top-center",
      });
      return rejectWithValue(error.response?.data || "An error occurred");
    }
  }
);

export const addWishlistItem = createAsyncThunk(
  "wishlist/addWishlistItem",
  async (item, { getState, rejectWithValue }) => {
    const state = getState();
    const wishlistItems = state.wishlist.products;
    const isItemInWishlist = wishlistItems.some(
      (wishlistItem) => wishlistItem.product_id === item.id
    );

    
    if (isItemInWishlist) {
      toast.info("Item is already in the wishlist", {
        position: "top-center",
      });
      return rejectWithValue("Item is already in the wishlist");
    }

    try {
      const response = await wishlistService.addItemToWishlist(item.id);
      const wishlistData = response.data;
      return { ...wishlistData, details: item };
    } catch (error) {
      return rejectWithValue(error.response?.data || "An error occurred");
    }
  }
);

export const deleteWishlistItem = createAsyncThunk(
  "wishlist/deleteWishlistItem",
  async (itemId, { rejectWithValue }) => {
    try {
      await wishlistService.deleteWishlistItem(itemId);
      return itemId;
    } catch (error) {
      return rejectWithValue(error.response?.data || "An error occurred");
    }
  }
);

const wishlistSlice = createSlice({
  name: "wishlist",
  initialState,
  reducers: {
    clearWishlist: (state) => {
      state.products = [];
      state.total = 0;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWishlistItems.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchWishlistItems.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.products = action.payload.items;
        state.total = action.payload.total;
        // toast.success("Wishlist items fetched successfully", {
        //   position: "top-center",
        // });
      })
      .addCase(fetchWishlistItems.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        toast.error("Failed to fetch wishlist items", {
          position: "top-center",
        });
      })
      .addCase(addWishlistItem.pending, (state) => {})
      .addCase(addWishlistItem.fulfilled, (state, action) => {
        // state.products.push(action.payload);
        state.total += 1;
        toast.success("Item added to wishlist", {
          position: "top-center",
        });
      })
      .addCase(addWishlistItem.rejected, (state, action) => {
        if (action.payload !== "Item is already in the wishlist") {
          state.error = action.payload;
          toast.error("Failed to add item to wishlist", {
            position: "top-center",
          });
        }
      })
      .addCase(deleteWishlistItem.pending, (state) => {})
      .addCase(deleteWishlistItem.fulfilled, (state, action) => {
        state.products = state.products.filter(
          (item) => item.id !== action.payload
        );
        state.total -= 1;
        toast.success("Item removed from wishlist", {
          position: "top-center",
        });
      })
      .addCase(deleteWishlistItem.rejected, (state, action) => {
        state.error = action.payload;
        toast.error("Failed to remove item from wishlist", {
          position: "top-center",
        });
      });
  },
});

export const { clearWishlist } = wishlistSlice.actions;

export default wishlistSlice.reducer;
