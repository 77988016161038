import React, { useState } from 'react';
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import InputAdornment from '@mui/material/InputAdornment';
import Box from "@mui/material/Box";
import { Link, useNavigate } from 'react-router-dom';
import Lock from "@mui/icons-material/Lock";
import Mail from "@mui/icons-material/Mail";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import { useDispatch } from 'react-redux';
import { login } from '../redux/slices/authSlice';
import PageTitle from '../components/core/PageTitle';
import { alpha, useTheme } from '@mui/material/styles';
import GoogleLoginButton from '../components/google-auth/GoogleLoginButton';
import Grid from "@mui/material/Grid";
import { TextFields } from '@mui/icons-material';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const theme = useTheme();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const result = await dispatch(login({ email, password }));
            console.log(result)
            if (login.fulfilled.match(result)) {
                navigate("/");
            }
        } catch (err) {
            console.error("Login error:", err);
            setError(err.message || 'Invalid email or password');
        }
    };

    const handleClickShowPassword = () => setShowPassword((show) => !show);


    return (
        <Box sx={{ minHeight: '100vh' }}>
            <PageTitle title={"Account"} />
            <Container sx={{ my: 5 }} maxWidth="lg">

                <Grid columnSpacing={2} rowGap={2} container>
                    <Grid sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} item md={6} xs={12}>


                        <Box sx={{ width: { md: "400px", xs: "200px" }, height: { md: "400px", xs: "200px" }, borderRadius: "71% 29% 39% 61% / 38% 65% 35% 62%", bgcolor: theme.palette.primary.main }}>
                            <img src='/cart-girl.png' />

                        </Box>

                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Card sx={{ p: 2, boxShadow: "none", bgcolor: alpha(theme.palette.secondary.main, 0.1) }}>
                            <CardContent>
                                <Typography variant="h4" align="center" gutterBottom>
                                    Login
                                </Typography>
                                <form onSubmit={handleSubmit}>
                                    <TextField
                                        label="Email"
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Mail />
                                                </InputAdornment>
                                            ),
                                        }}
                                        required
                                    />
                                    <TextField
                                        label="Password"
                                        type={showPassword ? 'text' : 'password'}
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Lock />
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={handleClickShowPassword}>
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        required
                                    />
                                    {error && (
                                        <Typography color="error" variant="body2" align="center">
                                            {error}
                                        </Typography>
                                    )}
                                    <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
                                        <Button type="submit" variant="contained" sx={{ backgroundColor: theme.palette.primary.main, color: "white" }}>
                                            LOGIN
                                        </Button>
                                    </Box>
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 5 }}>
                                        <Link to="/forgot-password">
                                            <Typography variant='body2' sx={{
                                                "&:hover": {
                                                    color: "red"
                                                }
                                            }}>
                                                Forgot password?
                                            </Typography>
                                        </Link>
                                        <Link to="/account/signup">
                                            <Typography variant='body2' sx={{
                                                "&:hover": {
                                                    color: "red"
                                                }
                                            }}>
                                                Create Account
                                            </Typography>
                                        </Link>
                                    </Box>
                                </form>
                                <Typography align="center" sx={{ mt: 4 }}>
                                    OR
                                </Typography>
                                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                                    {/* <IconButton color="primary" className="social-button mx-1">
                                <Facebook />
                            </IconButton>
                            <IconButton color="secondary" className="social-button mx-1">
                                <Google />
                            </IconButton>
                            <IconButton color="info" className="social-button mx-1">
                                <Twitter />
                            </IconButton> */}
                                    <GoogleLoginButton />
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>


            </Container>

        </Box>
    );
};

export default Login;
