import React from 'react'
import Collection from '../components/collection/Collection'
import PageTitle from '../components/core/PageTitle'
import { api } from '../apiCollection'
import Loading from '../components/core/Loading'
import { toast } from 'react-toastify'
import useAllData from '../hooks/useAllData'
import Error from '../components/core/Error'


export default function BestSellers() {
  const productUrl = api.products.getAll;

  const {allData, loading, error} = useAllData(productUrl);
  // console.log(allData)

  let bestSeller = allData?.filter(item=>item.best_seller);

  if(loading){
    return <Loading/>
  }  
  if (error) {
    return <Error code={error.request.status}/>;
  }
  return (
    <>
      <PageTitle title="Collection" />
        <Collection data={bestSeller} productCount={bestSeller.length}/>
    </>
  )
}
