import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import cartService from "../../services/cartService";
import api from "../../apiCollection";
import axios from "axios";

const initialState = {
  products: [],
  total: 0,
  count: 0,
  status: "idle",
  loading: {},
  error: null,
};


const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const axiosInstance = axios.create({
  maxRedirects: 5,
});

export const fetchProductDetails = async (productId) => {
  const fetchDetails = async () => {
    try {
      const response = await axiosInstance.get(
        `${api.products.getById}/${productId}`
      );
      return response.data.data;
    } catch (error) {
      if (error.response && error.response.status === 429) {
        await delay(2000);
        return fetchDetails();
      }
      throw error;
    }
  };
  return fetchDetails();
};

export const fetchCart = createAsyncThunk(
  "cart/fetchCart",
  async (_, { rejectWithValue }) => {
    try {
      const response = await cartService.getCartItems();

      if (response.status === 204) {
        return {
          items: [],
          total: 0,
        };
      }

      const allCartItems = response.data.CartItems;

      const cartItemsWithDetails = await Promise.all(
        allCartItems.map(async (item) => {
          try {
            const details = await fetchProductDetails(item.product_id);
            return { ...item, details };
          } catch (error) {
            // toast.error(
            //   `Failed to fetch product details for ${item.product_id}`,
            //   {
            //     position: "top-center",
            //   }
            // );
            console.log(error);
            throw error;
          }
        })
      );

      return {
        items: cartItemsWithDetails,
        total: cartItemsWithDetails.reduce(
          (total, item) => total + parseInt(item.details.discounted_price) * item.quantity,
          0
        ),
      };
    } catch (error) {
      return rejectWithValue(error.response?.data || "An error occurred");
    }
  }
);

export const addItemToCart = createAsyncThunk(
  "cart/addItemToCart",
  async (item, { rejectWithValue }) => {
    try {
      const response = await cartService.addItemToCart(
        item.id,
        item.product_unit
      );
      const cartItem = response.data.cart_items.find(
        (cartItem) => cartItem.product_id === item.id
      );
      return { ...cartItem, details: item };
    } catch (error) {
      return rejectWithValue(error.response?.data || "An error occurred");
    }
  }
);

export const removeItemFromCart = createAsyncThunk(
  "cart/removeItemFromCart",
  async (itemId, { rejectWithValue }) => {
    try {
      await cartService.deleteCartItem(itemId);
      return itemId;
    } catch (error) {
      return rejectWithValue(error.response?.data || "An error occurred");
    }
  }
);

export const updateItemQuantity = createAsyncThunk(
  "cart/updateItemQuantity",
  async ({ itemId, quantity, product_unit = "kg" }, { rejectWithValue }) => {
    try {
      const response = await cartService.updateCartItem(itemId, {
        quantity,
        product_unit,
      });
      return { id: itemId, quantity: response.quantity };
    } catch (error) {
      return rejectWithValue(error.response?.data || "An error occurred");
    }
  }
);

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading[action.payload.id] = action.payload.loading;
    },
    clearCart: (state) => {
      state.products = [];
      state.total = 0;
      state.count = 0;
    },
    
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCart.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCart.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.products = action.payload.items;
        state.total = action.payload.total;
        state.count = action.payload.items.length;
      })
      
      .addCase(fetchCart.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        // toast.error("Failed to fetch cart items", {
        //   position: "top-center",
        // });
      })
      .addCase(addItemToCart.pending, (state, action) => {
        state.loading[action.meta.arg.id] = true;
      })
      .addCase(addItemToCart.fulfilled, (state, action) => {
        state.loading[action.meta.arg.id] = false;
        const addedProduct = action.payload;
        const existingItem = state.products.find(item => item.id === addedProduct.id);
      
        if (existingItem) {
          const price = parseInt(addedProduct.details.discounted_price);
          existingItem.quantity += addedProduct.quantity;
          state.total += price * addedProduct.quantity;
        } else {
          
          // state.products.push(addedProduct);
          state.count += 1;
          const price = parseInt(addedProduct.details.discounted_price);
          state.total += price * addedProduct.quantity;
        }
      
        toast.success(`${addedProduct.details.name} added to cart`, {
          position: "top-center",
        });
      })
      
      .addCase(addItemToCart.rejected, (state, action) => {
        state.loading[action.meta.arg.id] = false;
        state.error = action.payload;
        toast.error("Failed to add item to cart", {
          position: "top-center",
        });
      })
      .addCase(removeItemFromCart.pending, (state, action) => {
        state.loading[action.meta.arg] = true;
      })
      .addCase(removeItemFromCart.fulfilled, (state, action) => {
        state.loading[action.meta.arg] = false;
        const itemId = action.payload;
        const item = state.products.find((item) => item.id === itemId);
        state.total -= parseInt(item.details.discounted_price) * item.quantity;
        state.products = state.products.filter((item) => item.id !== itemId);
        state.count -= 1;
        toast.success("Item removed from cart", {
          position: "top-center",
        });
      })
      
      .addCase(removeItemFromCart.rejected, (state, action) => {
        state.loading[action.meta.arg] = false;
        state.error = action.payload;
        toast.error("Failed to remove item from cart", {
          position: "top-center",
        });
      })
      .addCase(updateItemQuantity.pending, (state, action) => {
        state.loading[action.meta.arg.itemId] = true;
      })
      .addCase(updateItemQuantity.fulfilled, (state, action) => {
        state.loading[action.meta.arg.itemId] = false;
        const { id, quantity } = action.payload;
        const existingItem = state.products.find((item) => item.id === id);
        if (existingItem) {
          const price = parseInt(existingItem.details.discounted_price);
          state.total -= price * existingItem.quantity;
          existingItem.quantity = quantity;
          state.total += price * quantity;
        }
      })
      .addCase(updateItemQuantity.rejected, (state, action) => {
        state.loading[action.meta.arg.itemId] = false;
        state.error = action.payload;
        toast.error("Failed to update item quantity", {
          position: "top-center",
        });
      });
  },
});

export const { setLoading, clearCart } = cartSlice.actions;

export default cartSlice.reducer;
