import React, { useState } from 'react';
import {  useSelector } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import AddressItem from './AddressItem';
import AddressForm from './AddressForm';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import { useMediaQuery } from '@mui/material';
import Typography from '@mui/material/Typography';

export default function AddressList() {
    const [isAddingAddress, setIsAddingAddress] = useState(false);
    const [editingAddress, setEditingAddress] = useState(null);
    const address = useSelector(state => state.address)
    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));




    
    const handleAddAddressClick = () => {
        setEditingAddress(null);
        setIsAddingAddress(true);
    };

    const handleSaveAddress = () => {
        setIsAddingAddress(false);
        setEditingAddress(null);
    };

    const handleEditAddress = (address) => {
        setEditingAddress(address);
        setIsAddingAddress(true);
    };

    const handleCloseForm = () => {
        setIsAddingAddress(false);
        setEditingAddress(null);
    };

    return (
        isAddingAddress ? (
            <AddressForm
                onSave={handleSaveAddress}
                onClose={handleCloseForm}
                editingAddress={editingAddress}
            />
        ) : (
            <>
                <Box display="flex" my={2} flexDirection={isSmallScreen ? "column" : "row"}
                    gap={2}
                    justifyContent="space-between" alignItems="center" >
                    <Button
                        sx={{ borderRadius: "1.2rem" }}
                        variant="outlined"
                        startIcon={<AddIcon />}
                        onClick={handleAddAddressClick}>
                        Add new address
                    </Button>
                </Box>
                {
                    address.addresses.length === 0 ?
                    <Box sx={{display:"flex",flexDirection:"column", justifyContent:"center",alignItems:"center"}}>
                        <img  width={400} src="/address-not-found.webp" alt='address not found'/>
                         <Typography variant="h5" color="text.secondary">No saved addresses</Typography>

                    </Box>
                     : <List>
                    {address.addresses.map((address, index) => (
                        <AddressItem
                            key={index}
                            address={address}
                            onEdit={handleEditAddress}
                        />
                    ))}
                </List>
                }
                
            </>
        )
    );
}
