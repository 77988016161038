// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getMessaging, getToken} from "firebase/messaging"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyARq8xJ0TrMnRHfBJYkXo7r8Psut8MNZII",
  authDomain: "vegingo-be82f.firebaseapp.com",
  projectId: "vegingo-be82f",
  storageBucket: "vegingo-be82f.appspot.com",
  messagingSenderId: "672950832013",
  appId: "1:672950832013:web:41472b6f2b69992c77cbc4",
  measurementId: "G-6WNSM0XS60",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);
const analytics = getAnalytics(app);



export const generateToken = async() =>{
  const permission = await Notification.requestPermission();
  console.log(permission);

  if(permission === 'granted'){
    const token = await getToken(messaging,{
      vapidKey:"BNuDki-QW77cyizAEUjgEBScUxPaJsN0lsnQXTnURwYSnb13V9c5625iDwbdQBTL6z1TTdq5fc8TbTM88MPWtMY"
    })
  
    console.log(token);
  }
 


}