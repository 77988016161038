import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia'
import Rating from '@mui/material/Rating';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Swiper, SwiperSlide } from 'swiper/react';
// import bgImg from "../../assets/Testimonials.jpg"
import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"
import { useTheme } from "@mui/material/styles"
import 'swiper/css';
import 'swiper/css/effect-cards';
import 'swiper/css/navigation';
import { EffectCards, Navigation } from 'swiper/modules';
import 'swiper/css/bundle';
import SectionTitle from '../core/SectionTitle';
import quoteOpeningIMG from "../../assets/quote-opening.svg"
import quoteClosingIMG from "../../assets/quote-closing.svg";

const baseCloudinaryURL = process.env.REACT_APP_CLOUDINARY_BASE_URL;

const bgImageURL = `${baseCloudinaryURL}/w_1200,h_600,c_fill,q_auto,f_auto/v1722407340/vegingo/Testimonials_qim94x.jpg`;


const QuoteOpening = ({ color }) => {
  return (
    <svg fill={color} version="1.1" id="Layer_1" width="60px" height="60px" viewBox="0 0 70 70" enableBackground="new 0 0 70 70">
      <g id="SVGRepo_bgCarrier" strokeWidth="0" />
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
      <g id="SVGRepo_iconCarrier">
        <g>
          <path d="M27.792,8.103v9.427c-5.957,1.364-8.811,5.091-9.185,12.032h9.185v22.336H7.813V33.531 C7.813,20.257,13.521,10.083,27.792,8.103 M27.792,4.103c-0.183,0-0.366,0.013-0.55,0.038C12.134,6.237,3.813,16.675,3.813,33.531 v18.366c0,2.209,1.791,4,4,4h19.979c2.209,0,4-1.791,4-4V29.562c0-2.209-1.791-4-4-4h-4.411c0.988-2.571,2.832-3.567,5.304-4.133 c1.818-0.417,3.107-2.034,3.107-3.899V8.103c0-1.156-0.501-2.257-1.373-3.017C29.687,4.448,28.751,4.103,27.792,4.103L27.792,4.103 z" />
          <path d="M62.187,18.103v9.427c-5.955,1.364-8.809,5.091-9.183,12.032h9.183v22.336H42.208V43.531 C42.208,30.257,47.917,20.083,62.187,18.103 M62.187,14.103c-0.183,0-0.366,0.013-0.55,0.038 c-15.108,2.097-23.429,12.534-23.429,29.391v18.366c0,2.209,1.791,4,4,4h19.979c2.209,0,4-1.791,4-4V39.562c0-2.209-1.791-4-4-4 h-4.409c0.988-2.571,2.831-3.567,5.303-4.133c1.817-0.417,3.106-2.034,3.106-3.899v-9.427c0-1.156-0.501-2.257-1.373-3.017 C64.081,14.448,63.146,14.103,62.187,14.103L62.187,14.103z" />
          <g>
            <path d="M24.583,45.565c-0.553,0-1-0.447-1-1V33.583h-4.654c-0.553,0-1-0.447-1-1s0.447-1,1-1h6c0.553,0,0.654,0.43,0.654,0.982 v12C25.583,45.118,25.136,45.565,24.583,45.565z" />
          </g>
          <g>
            <path d="M59.583,57.565c-0.553,0-1-0.447-1-1v-2c0-0.553,0.447-1,1-1s1,0.447,1,1v2C60.583,57.118,60.136,57.565,59.583,57.565z" />
          </g>
          <g>
            <path d="M59.583,51.565c-0.553,0-1-0.447-1-1v-6.982h-4.654c-0.553,0-1-0.447-1-1s0.447-1,1-1h6c0.553,0,0.654,0.43,0.654,0.982v8 C60.583,51.118,60.136,51.565,59.583,51.565z" />
          </g>
        </g>
      </g>
    </svg>
  );
};


const QuoteClosing = ({ color }) => {
  return (
    <svg fill={color} version="1.1" id="Layer_1" width="60px" height="60px" viewBox="0 0 70 70" enableBackground="new 0 0 70 70" transform="matrix(1, 0, 0, 1, 0, 0)rotate(180)">
      <g id="SVGRepo_bgCarrier" strokeWidth="0" />
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
      <g id="SVGRepo_iconCarrier">
        <g>
          <path d="M27.792,8.103v9.427c-5.957,1.364-8.811,5.091-9.185,12.032h9.185v22.336H7.813V33.531 C7.813,20.257,13.521,10.083,27.792,8.103 M27.792,4.103c-0.183,0-0.366,0.013-0.55,0.038C12.134,6.237,3.813,16.675,3.813,33.531 v18.366c0,2.209,1.791,4,4,4h19.979c2.209,0,4-1.791,4-4V29.562c0-2.209-1.791-4-4-4h-4.411c0.988-2.571,2.832-3.567,5.304-4.133 c1.818-0.417,3.107-2.034,3.107-3.899V8.103c0-1.156-0.501-2.257-1.373-3.017C29.687,4.448,28.751,4.103,27.792,4.103L27.792,4.103 z" />
          <path d="M62.187,18.103v9.427c-5.955,1.364-8.809,5.091-9.183,12.032h9.183v22.336H42.208V43.531 C42.208,30.257,47.917,20.083,62.187,18.103 M62.187,14.103c-0.183,0-0.366,0.013-0.55,0.038 c-15.108,2.097-23.429,12.534-23.429,29.391v18.366c0,2.209,1.791,4,4,4h19.979c2.209,0,4-1.791,4-4V39.562c0-2.209-1.791-4-4-4 h-4.409c0.988-2.571,2.831-3.567,5.303-4.133c1.817-0.417,3.106-2.034,3.106-3.899v-9.427c0-1.156-0.501-2.257-1.373-3.017 C64.081,14.448,63.146,14.103,62.187,14.103L62.187,14.103z" />
          <g>
            <path d="M24.583,45.565c-0.553,0-1-0.447-1-1V33.583h-4.654c-0.553,0-1-0.447-1-1s0.447-1,1-1h6c0.553,0,0.654,0.43,0.654,0.982 v12C25.583,45.118,25.136,45.565,24.583,45.565z" />
          </g>
          <g>
            <path d="M59.583,57.565c-0.553,0-1-0.447-1-1v-2c0-0.553,0.447-1,1-1s1,0.447,1,1v2C60.583,57.118,60.136,57.565,59.583,57.565z" />
          </g>
          <g>
            <path d="M59.583,51.565c-0.553,0-1-0.447-1-1v-6.982h-4.654c-0.553,0-1-0.447-1-1s0.447-1,1-1h6c0.553,0,0.654,0.43,0.654,0.982v8 C60.583,51.118,60.136,51.565,59.583,51.565z" />
          </g>
        </g>
      </g>
    </svg>
  );
};




const testimonials = [
  {
    text: "I absolutely love this product! It's made a huge difference in my life.",
    author: "Jane Doe",
    rating: 5,
    profileImage: "https://images.unsplash.com/photo-1534528741775-53994a69daeb?q=80&w=1964&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
  },
  {
    text: "Amazing service! The team was very helpful and responsive.",
    author: "Jane Smith",
    rating: 4,
    profileImage: "https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
  },
  {
    text: "This is the best app I've ever used. It's so intuitive and user-friendly.",
    author: "Woofer",
    rating: 4.5,
    profileImage: "https://images.unsplash.com/photo-1536164261511-3a17e671d380?q=80&w=1882&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
  }
];


const Testimonials = () => {

  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
  const theme = useTheme();

  return (
    <Box sx={{

      mt: 5,
      textAlign:"center"

    }}>

      <Box sx={{
        backgroundColor: theme.palette.secondary.main,
        minHeight: '60vh',
        height: "100%",
        p: 2,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        color: '#fff',
        overflow: "hidden",
      }}>

        <Container maxWidth="lg">

          <Grid container columnSpacing={5}>
            <Grid item md={6} xs={12} sx={{ textAlign: 'left' }}>
              <Typography gutterBottom color="primary" sx={{fontFamily:"Playfair Display"}} variant={isSmallScreen ? 'h4' : 'h3'} >What they're talking about our company ?</Typography>
              <Typography variant='body1'>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequatur inventore omnis cum facere culpa nihil, provident porro enim. Corporis eius neque placeat optio mollitia asperiores eos minima est, ipsa necessitatibus dignissimos unde saepe adipisci porro assumenda, accusamus quibusdam qui possimus obcaecati voluptatem nulla enim laboriosam. Dolor nulla quisquam cumque sunt!</Typography>

            </Grid>

            <Grid sx={{ p: 2 }} item md={6} xs={12} >

              <Swiper
                effect={'cards'}
                grabCursor={true}
                modules={[EffectCards, Navigation]}
                navigation={isSmallScreen ? false : true}
                className='mySwiper2'
                style={{
                  '--swiper-navigation-color': theme.palette.primary.main,
                  '--swiper-navigation-size': '30px',
                  height:"100%",
                  width:"100%"

                }}

              >
                {testimonials.map((testimonial, index) => (
                  <SwiperSlide style={{ position: "relative", backgroundColor: theme.palette.secondary.main, borderWidth: 2, borderStyle: "solid", borderColor: theme.palette.primary.main, borderRadius:10 }} key={index}>


                    <div style={{ position: "absolute", left: "30px", top: "20px" }} >
                      <QuoteOpening color={theme.palette.primary.main} />

                    </div>

                    <div style={{ position: "absolute", right: "30px", bottom: "20px" }} >
                      <QuoteClosing color={theme.palette.primary.main} />

                    </div>


                    <Card
                      style={{
                        background: "transparent",
                        boxShadow: 'none',
                        color:"white",
                        margin: '0 auto',
                        maxWidth: '800px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        padding: '20px',
                        
                      }}
                    >
                      <CardMedia
                        component="img"
                        image={testimonial.profileImage}
                        alt="Profile"
                        style={{ width: '100px', height: "100px", borderRadius: '50%', marginBottom: '10px' }}
                      />
                      <CardContent style={{ textAlign: 'center' }}>
                        <Typography variant="body1" sx={{ fontWeight: 500 }}>{testimonial.text}</Typography>
                        <Typography variant="h6" sx={{ marginTop: '10px', marginBottom: '10px', fontWeight: 600 }}>{testimonial.author}</Typography>
                        <Rating value={testimonial.rating} precision={0.5} readOnly />
                      </CardContent>
                    </Card>
                  </SwiperSlide>
                ))}
              </Swiper>

            </Grid>

          </Grid>

        </Container>
      </Box>




    </Box>

  );
};

export default Testimonials;
