import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import Container from '@mui/material/Container';
import ProductCard from '../product/ProductCard';
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Divider, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import SectionTitle from '../core/SectionTitle';

export default function SpecialProducts({ specialProducts }) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Container maxWidth="lg" sx={{ mt: 5 }}>
      <SectionTitle t1={"Special Products"} t2={"Featured and Exclusive Finds"}/>

      <Box sx={{ position: "relative", height: "220px" }}>
        <Swiper
          spaceBetween={15}
          navigation={isSmallScreen ? false : { 
            nextEl: '.swiper-button-next-our-products',
            prevEl: '.swiper-button-prev-our-products',
          }}
          modules={[Navigation]}
          className="mySwiper"
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            480: {
              slidesPerView: 2,
              spaceBetween: 15,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 25,
            },
          }}
          style={{ height: "100%", width: "100%", padding: 5 }}
        >
          {specialProducts.slice(0, 10).map(item => (
            <SwiperSlide style={{ height: "100%" }} key={item.id}>
              <ProductCard productData={item} flexDirection={"row"} button={true} />
            </SwiperSlide>
          ))}
        </Swiper>

        {!isSmallScreen && ( 
          <div style={{ position: "absolute", top: "-40px", right: "0px" }}>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: 5 }}>
              <div
                className="swiper-button-prev-our-products"
                style={{
                  backgroundColor: theme.palette.primary.main,
                  color: "white",
                  borderRadius: "50%",
                  width: "30px",
                  height: "30px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
              >
                <ArrowBackIcon />
              </div>

              <div
                className="swiper-button-next-our-products"
                style={{
                  backgroundColor: theme.palette.primary.main,
                  color: "white",
                  borderRadius: "50%",
                  width: "30px",
                  height: "30px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
              >
                <ArrowForwardIcon />
              </div>
            </div>
          </div>
        )}
      </Box>
    </Container>
  );
}
