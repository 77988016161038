import React, { useState } from 'react';
import { Button, ButtonGroup, Typography } from '@mui/material';
import { styled } from '@mui/system';

const categories = [
    { id: 0, name: 'All' },
    { id: 1, name: 'Vegetables' },
    // { id: 2, name: 'Seasonal Vegetables' },
    { id: 3, name: 'Fruits' },
];

const StyledButton = styled(Button)(({ theme, selected }) => ({
    backgroundColor: selected ? theme.palette.primary.main : '#F1F3F4',
    color: selected ? '#FFF' : '#000',
    borderRadius: '20px',
    padding: '5px 15px',
    margin: '0 5px',
    '&:hover': {
        backgroundColor: selected ? theme.palette.primary.main : '#E0E0E0',
    },
}));

const CategoryFilter = ({ selectedCategoryId, onCategoryChange }) => {
    return (
        <ButtonGroup>
            {categories.map((category) => (
                <StyledButton
                    key={category.id}
                    onClick={() => onCategoryChange(category.id)}
                    selected={selectedCategoryId === category.id}
                >
                    <Typography variant='caption' fontWeight={600}>
                        {category.name}
                    </Typography>
                </StyledButton>
            ))}
        </ButtonGroup>
    );
};

export default CategoryFilter;
