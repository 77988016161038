import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemText from "@mui/material/ListItemText"
import Grid from "@mui/material/Grid"
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

const CategoryDropdown = ({ isOpen, categories }) => {
    const { t } = useTranslation('navbar');
    const theme = useTheme();
    return (
        <Box width={"300px"} sx={{
            position: "absolute",
            top: '100%',
            left: '0px',
            bgcolor: theme.palette.background.default,
            boxShadow: 3,
            borderRadius: '4px',
            zIndex: 10,
            padding: 2,
            opacity: isOpen ? 1 : 0,
            transform: isOpen ? "scale(1)" : "scale(0)",
            transition: "opacity 300ms ease-in-out, transform 300ms ease-in-out",
        }}>
            <Grid container spacing={2}>
                {Object.keys(categories).map((category) => (
                    <Grid item key={category} lg={6}>
                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', bgcolor: theme.palette.primary.main, color: "white", px: 1, borderRadius: "0.2rem" }}>
                            {t(category)}
                        </Typography>
                        <List>
                            {categories[category].map((item) => (
                                <ListItem
                                    key={item}
                                    sx={{ textDecoration: "none",color: theme.palette.text.primary, whiteSpace: "nowrap", p: 0, "&:hover": { color: theme.palette.primary.main } }}
                                    component={Link}
                                    to={`/products/${item}`}
                                >
                                    <ListItemText primary={`- ${t(item)}`} />
                                </ListItem>
                            ))}
                        </List>
                    </Grid>
                ))}
            </Grid>
        </Box>
    )
};

export default CategoryDropdown;