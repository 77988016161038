import React from 'react'
import Collection from '../components/collection/Collection'
import PageTitle from '../components/core/PageTitle'
import {api} from "../apiCollection"
import Loading from '../components/core/Loading'
import { toast } from 'react-toastify'
import useAllData from '../hooks/useAllData'

export default function DealOfTheDay() {
  const productUrl = api.products.getAll;

  const {allData, loading, error} = useAllData(productUrl);

  let dealOfTheDay = allData?.filter(item=>item.deal_of_the_day);

  if (loading) {
    return <Loading/>
  }
  if (error) {
    return toast.error(error.message, {
      position:"top-center"
      
      
    })
  }
  return (
    <>
      <PageTitle title="Collection" />
      <Collection data={dealOfTheDay} />
    </>
  )
}
