import React from 'react'
import Box from "@mui/material/Box"
import Typography from '@mui/material/Typography';
import Button from "@mui/material/Button"
// import ToggleButton from "@mui/material/ToggleButton"
// import ToggleButtonGroup from "@mui/material/ToggleButtonGroup"
import { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import LocalShippingOutlined from '@mui/icons-material/LocalShippingOutlined';
import RemoveRedEyeOutlined from '@mui/icons-material/RemoveRedEyeOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { addItemToCart } from '../../redux/slices/cartSlice';
import { addWishlistItem } from '../../redux/slices/wishlistSlice';
// import { toast } from 'react-toastify';
import { useTheme } from '@mui/material/styles';
import EastRoundedIcon from '@mui/icons-material/EastRounded';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import { CircularProgress } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';


export default function ProductDetail({ productData}) {
    const { isAuthenticated } = useSelector(state => state.auth);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();


    const handleAddToWishlist = async () => {
        if (isAuthenticated) {
            await dispatch(addWishlistItem(productData));
        } else {
            navigate('/account/login');
        }
    };


    const handleAddToCart = async () => {
        if (isAuthenticated) {
            setIsLoading(true);
            await dispatch(addItemToCart(productData));
            setIsLoading(false);
            
        } else {
            navigate('/account/login');
        }

    };
    const [quantity, setQuantity] = useState(1);
    // const [selectedWeight, setSelectedWeight] = useState('3 kg');
    // const [selectedContainer, setSelectedContainer] = useState('Bag');
    // const [selectedNutrient, setSelectedNutrient] = useState('Vitamin C');

    const location = useLocation();

    const isProductPage = location.pathname.split("/")[1] === "products";
    const theme = useTheme();


    const handleToggle = (setter) => (event, newValue) => {
        if (newValue !== null) {
            setter(newValue);
        }
    };
    return (
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 2, height: "100%", width: "100%" }}>
            <Box sx={{ width: { xs: '100%', md: '800px' }, height: "100%", borderRadius: "0px" }}>
                <img
                    style={{ height: '100%', width: '100%', borderRadius: "0.3rem", objectFit: 'cover' }}
                    src={productData.image}
                    alt={productData.name}
                />
            </Box>

            <Box className="scroll" sx={{
                overflowY: 'auto',
                overflowX: 'hidden',
                "&::-webkit-scrollbar-thumb": {
                    background: theme.palette.primary.main
                },
                height: "100%",
                pr: 2
            }}>

                <Box sx={{ width: "100%", gap: 1, p: 2 }}>
                    <Typography gutterBottom variant="h6" sx={{ fontWeight: 800 }}>
                        {productData.name}
                    </Typography>

                    <Typography sx={{ fontWeight: 500, color: "gray" }} variant={"body2"} gutterBottom>
                        {productData.description || "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia perspiciatis animi et eum cum, exercitationem saepe soluta libero aliquid adipisci!"}

                    </Typography>

                    <Box sx={{ display: "flex", gap: 2 }}>
                        <Typography gutterBottom variant="h6" sx={{
                            color: productData.discounted_price ? "gray" : theme.palette.primary.main,
                            textDecoration: productData.discounted_price ? "line-through" : "none", fontWeight: 600
                        }}>
                            ₹ {productData.price || "2.4"}
                        </Typography>
                        <Typography gutterBottom variant="h6" sx={{ color: theme.palette.primary.main, fontWeight: 600 }}>
                            ₹ {productData.discounted_price || "2.4"}
                        </Typography>


                    </Box>



                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2, gap: 2 }}>
                        <Typography variant={'body2'} sx={{ fontWeight: 700, flex: 4, textTransform: "uppercase" }}>Vendor</Typography>
                        <Typography variant={'body2'} sx={{ fontWeight: 500, flex: 8, color: "gray" }}>{productData.brand || "Fresho"}</Typography>

                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2, gap: 2 }}>
                        <Typography variant={'body2'} sx={{ fontWeight: 700, flex: 4, textTransform: "uppercase" }}>Type</Typography>
                        <Typography variant={'body2'} sx={{ fontWeight: 500, color: "gray", flex: 8 }}>{productData.category_id === 1 || 2 ? "Vegetable" : "Fruit"}</Typography>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2, gap: 2 }}>
                        <Typography variant={'body2'} sx={{ fontWeight: 700, flex: 4, textTransform: "uppercase" }}>Availability</Typography>
                        <Typography variant={'body2'} sx={{ fontWeight: 500, color: "gray", flex: 8 }}>{productData.is_in_stock ? "In Stock" : "Out of Stock"}</Typography>
                    </Box>

                    {/* <Box sx={{ display: 'flex', mt: 2 }}>
                        <Typography variant={'body2'} sx={{ fontWeight: 700, flex: 4, textTransform: "uppercase" }}>
                            Weight
                        </Typography>
                        <ToggleButtonGroup
                            size='small'
                            value={selectedWeight}
                            exclusive
                            onChange={handleToggle(setSelectedWeight)}
                            aria-label="weight"
                            sx={{ ml: 2, flex: 8 }}
                        >
                            <ToggleButton sx={{ "&.Mui-selected": { color: "white", bgcolor: theme.palette.primary.main } }} value="3 kg">3 kg</ToggleButton>
                            <ToggleButton sx={{ "&.Mui-selected": { color: "white", bgcolor: theme.palette.primary.main } }} value="2 kg">2 kg</ToggleButton>
                            <ToggleButton sx={{ "&.Mui-selected": { color: "white", bgcolor: theme.palette.primary.main } }} value="1 kg">1 kg</ToggleButton>
                            <ToggleButton sx={{ "&.Mui-selected": { color: "white", bgcolor: theme.palette.primary.main } }} value="1/2 kg">1/2 kg</ToggleButton>
                        </ToggleButtonGroup>
                    </Box> */}

                    {/* <Box sx={{ display: 'flex', mt: 2 }}>
                        <Typography variant={'body2'} sx={{ fontWeight: 700, flex: 4, textTransform: "uppercase" }}>
                            Container
                        </Typography>
                        <ToggleButtonGroup
                            size='small'
                            value={selectedContainer}
                            exclusive
                            onChange={handleToggle(setSelectedContainer)}
                            aria-label="container"
                            sx={{ ml: 2, flex: 8 }}
                        >
                            <ToggleButton sx={{ "&.Mui-selected": { color: "white", bgcolor: theme.palette.primary.main } }} value="Bag">Bag</ToggleButton>
                            <ToggleButton sx={{ "&.Mui-selected": { color: "white", bgcolor: theme.palette.primary.main } }} value="Tray">Tray</ToggleButton>
                            <ToggleButton sx={{ "&.Mui-selected": { color: "white", bgcolor: theme.palette.primary.main } }} value="Jar">Jar</ToggleButton>
                            <ToggleButton sx={{ "&.Mui-selected": { color: "white", bgcolor: theme.palette.primary.main } }} value="Pouch">Pouch</ToggleButton>
                        </ToggleButtonGroup>
                    </Box> */}



                    <Box sx={{ display: 'flex', mt: 2, gap: 2 }}>
                        <Typography variant={'body2'} sx={{ fontWeight: 700, flex: 4, textTransform: "uppercase" }}>
                            Quantity (kg)
                        </Typography>

                        <Box sx={{ display: 'flex', alignItems: 'center', flex: 8 }}>
                            <div style={{ border: "0.2px solid lightgray", borderRadius: "0.3rem" }}>
                                <Button size='small' sx={{ "&:hover": { bgcolor: theme.palette.primary.main, color: "white" }, maxWidth: "30px", maxHeight: "30px", minWidth: "30px", minHeight: "30px", borderRight: "1px solid lightGray", borderTopRightRadius: "0px", borderBottomRightRadius: "0px", }} onClick={() => setQuantity(quantity > 1 ? quantity - 1 : 1)}>-</Button>

                                <input type='number' value={quantity}   onChange={(e) => setQuantity(Number(e.target.value) || undefined)} style={{ border: "none", width: "50px", height: "30px", textAlign: "center", backgroundColor: "transparent", color: "gray" }} />

                                <Button sx={{ "&:hover": { bgcolor: theme.palette.primary.main, color: "white" }, maxWidth: "30px", maxHeight: "30px", minWidth: "30px", minHeight: "30px", borderLeft: "1px solid lightGray", borderTopLeftRadius: "0px", borderBottomLeftRadius: "0px" }} onClick={() => setQuantity(quantity + 1)}>+</Button>

                            </div>

                        </Box>
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, gap: 2 }}>

                        <Typography whiteSpace="nowrap" variant="h6" sx={{ mt: 2, flex: 4, fontWeight: 600 }}>
                            Sub total :
                        </Typography>
                        <Typography color="primary" variant="h6" sx={{ mt: 2, flex: 8, fontWeight: 600 }}>
                            ₹ {productData.discounted_price ? (productData.discounted_price * quantity).toFixed(2) : (productData.price * quantity).toFixed(2)}
                        </Typography>

                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems:"center", mt: 2 }}>
                        <Button size='small' onClick={handleAddToCart} sx={{ bgcolor: theme.palette.primary.main, borderRadius: "1.2rem", color: "white", whiteSpace: "nowrap" }} variant="contained" fullWidth>
                            {!isLoading ? (
                                <>
                                <Typography variant='subtitle2'>Add to cart</Typography>
                                
                                <ShoppingBagIcon sx={{ml:1,fontSize:"1rem" }}/>
                                </>
                            ) : (
                                <CircularProgress sx={{ color: "white"}} size={20} />
                            )}
                        </Button>
                        <Button size='small' onClick={handleAddToWishlist} sx={{ bgcolor: theme.palette.primary.main, borderRadius: "1.2rem",display:"flex",alignItems:"center", ml: 2, color: "white", whiteSpace: "nowrap" }} variant="contained" fullWidth>
                        <Typography variant='subtitle2'>Add to wishlist</Typography>
                            <FavoriteIcon sx={{ml:1,fontSize:"1rem"}}/>
                        </Button>
                    </Box>

                    <Button size='small' fullWidth sx={{ bgcolor: theme.palette.primary.main, mt: 2.5, borderRadius: "1.2rem", color: "white" }} variant='contained'>Buy it Now</Button>


                    {
                        !isProductPage && <Box sx={{ mt: 3 }} > <Link to={`/products/${productData.name}`} >
                            <Box sx={{
                                display: "flex", gap: 1, alignItems: "center",
                                justifyContent: "center", "&:hover": {
                                    color: theme.palette.primary.main
                                }
                            }}>
                                <Typography variant='body2' sx={{ fontWeight: 500 }}>View Details</Typography>
                                <EastRoundedIcon fontSize='small' />
                            </Box>

                        </Link></Box>

                    }


                    {
                        isProductPage && (
                            <Box sx={{ display: "flex", flexDirection: "column", gap: 3, my: 3 }}>
                                <Box sx={{ display: "flex", alignItems: "center", gap: 3 }} >
                                    <LocalShippingOutlined fontSize="medium" />
                                    <Typography variant='subtitle1'>Estimated deliver 5-7 days</Typography>
                                </Box>

                                <Box sx={{ display: "flex", alignItems: "center", gap: 3 }} >
                                    <RemoveRedEyeOutlined fontSize="medium" />
                                    <Typography variant='subtitle1'>200 people are viewing this right now</Typography>
                                </Box>



                            </Box>


                        )
                    }

                </Box>

            </Box>

        </Box>

    )
}
