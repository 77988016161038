import React from 'react';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import Grid from "@mui/material/Grid"
import Container from '@mui/material/Container';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Divider from "@mui/material/Divider"
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import PinterestIcon from '@mui/icons-material/Pinterest';
import InstagramIcon from '@mui/icons-material/Instagram';
import LanguageSwitcher from '../../i18n/SwitchLanguage';
import LinkedInIcon from "@mui/icons-material/LinkedIn"

export default function TopBar() {
  const theme = useTheme();

  return (
    <div style={{ backgroundColor: theme.palette.primary.main, width: "100%", color: "white" }}>
      <Container maxWidth="xl">
        <Grid
          container
          sx={{ py: 0.2, gap: 1 }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: "0.5rem"
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                <EmailIcon sx={{ fontSize: "1rem" }} />
                <Typography variant="caption">vegingo.help@gmail.com</Typography>
              </Box>

              {/* small divider for large screens */}
              <Divider orientation="vertical" flexItem color="white" />

              <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                <PhoneIcon sx={{ fontSize: '1rem' }} />
                <Typography variant="caption">0000-123456789</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
              {/* language select button */}
              <LanguageSwitcher />
              <IconButton color="inherit">
                <TwitterIcon sx={{ fontSize: '1.2rem' }} />
              </IconButton>

              <a target='_blank' href='https://www.facebook.com/profile.php?id=61564613017498&mibextid=ZbWKwL'>
                <IconButton color="inherit">
                  <FacebookIcon sx={{ fontSize: '1.2rem' }} />
                </IconButton>
              </a>

              <a target='_blank' href="https://www.instagram.com/vegingo.ig?igsh=MWR1c29jMjJoMGNzNw==">
                <IconButton color="inherit">
                  <InstagramIcon sx={{ fontSize: '1.2rem' }} />
                </IconButton>
              </a>
              <a target='_blank' href="https://www.linkedin.com/company/vegingo/">
                <IconButton color="inherit">
                  <LinkedInIcon sx={{ fontSize: '1.2rem' }} />
                </IconButton>
              </a>

            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
