import React, { useEffect, useState } from 'react';
import Paper from "@mui/material/Paper";
import Typography from '@mui/material/Typography';
import Box from "@mui/material/Box";
import { useDispatch, useSelector } from 'react-redux';
import { alpha, Divider } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { setSelectedAddress as setReduxSelectedAddress } from '../../redux/slices/orderSlice';
import { Link } from 'react-router-dom';

const AddressLabel = ({ address }) => {
    return (
        <Box sx={{py:2,my:1}}>
            <Box>
                <Typography gutterBottom variant='body2' sx={{fontWeight:"bold"}}>
                    {address.user_name} {address.is_default === 1 && "(default)"}
                </Typography>
                <Typography variant='body2'>
                    {address.building_name_area}, {address.landmark}
                </Typography>
                <Typography gutterBottom variant='body2'>
                    {address.city}, {address.state}, {address.pincode}
                </Typography>
                <Typography variant="body2">
                    Mobile: <span style={{fontWeight:"bold"}}>{address.phone_number}</span>
                </Typography>
            </Box>
        </Box>
    );
}

const SelectAddressModal = ({ open, handleClose, addresses, setSelectedAddress, onAddAddress }) => {
    const [selectedValue, setSelectedValue] = useState(null);

    useEffect(() => {
        if (addresses.length > 0) {
            const defaultAddr = addresses.find(address => address.is_default === 1);
            if (defaultAddr) {
                setSelectedValue(defaultAddr.id);  
                setSelectedAddress(defaultAddr);
            }
        }
    }, [addresses, setSelectedAddress]);

    const handleAddressChange = (e) => {
        const selectedAddr = addresses.find(addr => addr.id === parseInt(e.target.value));
        setSelectedValue(e.target.value);
        setSelectedAddress(selectedAddr);
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 350,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 2,
                display: "flex",
                flexDirection: "column"
            }}>
                <Typography variant='h7' sx={{ fontWeight: 500, py: 1 }}>Select Delivery Address</Typography>
                <Divider flexItem />

                {addresses.length > 0 ? (
                    <FormControl sx={{ height: "300px", overflowY: "auto" }} >
                        <RadioGroup
                            aria-labelledby="address-radio-buttons-group-label"
                            value={selectedValue}
                            onChange={handleAddressChange}
                            name="radio-buttons-group"
                        >
                            {addresses.map((address, index) => (
                                <FormControlLabel
                                    key={index}
                                    value={address.id}
                                    control={<Radio />}
                                    label={<AddressLabel address={address} />}
                                    sx={{ boxShadow: 1, m: 0 }}
                                />
                            ))}
                        </RadioGroup>
                    </FormControl>
                ) : (
                    <Box sx={{ textAlign: 'center', mt: 2 }}>
                        <Typography variant="body1" sx={{ mb: 2 }}>
                            No address available. Please add a new address.
                        </Typography>
                        <Link to="/account/address">
                        <Button variant="contained" size='small' color="primary" sx={{color:"white",borderRadius:"1.2rem"}} onClick={onAddAddress}>
                            Add Address
                        </Button>
                        </Link>
                     
                    </Box>
                )}
            </Box>
        </Modal>
    );
};


export default function AddressSelector() {
    const [modalOpen, setModalOpen] = useState(false);
    const address = useSelector(state => state.address);
    const dispatch = useDispatch();
    const theme = useTheme();
    const defaultAddress = address?.addresses?.find(address => address.is_default === 1);
    const [selectedAddress, setSelectedAddress] = useState(defaultAddress);

    useEffect(() => {
        if (selectedAddress) {
            dispatch(setReduxSelectedAddress(selectedAddress));
        }
    }, [selectedAddress, dispatch]);

    const handleModalOpen = () => setModalOpen(true);
    const handleModalClose = () => setModalOpen(false);

    return (
        <Paper sx={{ backgroundColor: alpha(theme.palette.primary.main, 0.1), px: 1, py: 2, display: "flex", justifyContent: "space-between", mb: 2,flexWrap:"wrap",gap:2 }}>
            <Box>
                {selectedAddress ? (
                    <>
                        <Typography variant='body2'>
                            Deliver to: <span style={{ fontWeight: "bold" }}>{selectedAddress.user_name}, {selectedAddress.pincode}</span>
                        </Typography>
                        <Typography variant='body2'>
                            {selectedAddress.building_name_area}, {selectedAddress.city}, {selectedAddress.country}
                        </Typography>
                    </>
                ) : (
                    <Typography variant='body2'>
                        No default address found.
                    </Typography>
                )}
            </Box>

            <Button size='small' variant="outlined" onClick={handleModalOpen} sx={{whiteSpace:"nowrap"}}>Change Address</Button>

            <SelectAddressModal open={modalOpen} handleClose={handleModalClose} addresses={address.addresses}  setSelectedAddress={setSelectedAddress} />
        </Paper>
    );
}
