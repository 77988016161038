import Avatar from "@mui/material/Avatar"
import Box from "@mui/material/Box"
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography";
import React from 'react'
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import PinterestIcon from '@mui/icons-material/Pinterest';
import InstagramIcon from '@mui/icons-material/Instagram';
import { useTheme } from '@mui/material/styles';


export default function TeamCard({name,title}) {
    const theme = useTheme();
  return (
    
    <Paper sx={{position:"relative",padding: 3, textAlign: 'center', color: 'text.secondary',overflow:"hidden","&:hover .link-popup":{
        top:0
    } }}>
      <Avatar alt="Founder" src="[founder-image-url]" sx={{ margin: 'auto', width: 120, height: 120 }} />
      <Typography variant="h6" component="h2">
        {name}
      </Typography>
      <Typography variant="body1" component="p">
        {title}
      </Typography>


      {/* link pop ups */}
      <Box className="link-popup" sx={{position:"absolute",width:"100%", height:"100%", top:"100%", left:0, bgcolor:"rgba(0,0,0,0.5)", display:"flex", alignItems:"flex-end", transition:"all 300ms ease-in-out"}}>
      <Box sx={{width:"100%",display:"flex",alignItems:"center", justifyContent:"center", gap:1,p:1}}>
        <IconButton sx={{bgcolor:theme.palette.primary.main, color:"white"}}>
            <FacebookIcon/>

        </IconButton>
        <IconButton sx={{bgcolor:theme.palette.primary.main, color:"white"}}>
            <InstagramIcon/>

        </IconButton>
        <IconButton sx={{bgcolor:theme.palette.primary.main, color:"white"}}>
            <PinterestIcon/>

        </IconButton>
        <IconButton sx={{bgcolor:theme.palette.primary.main, color:"white"}}>
            <TwitterIcon/>

        </IconButton>
        
         

      </Box>

      </Box>
    
    </Paper>
  
  )
}
