import axios from "axios";
import authService from "./authService";
import api from "../apiCollection";

const getAuthToken = () => {
  const user = authService.getCurrentUser();
  const token = user ? user.token : null;
  console.log("AuthToken:", token);
  return token;
};

const getQR = async () => {
  try {
    const token = getAuthToken();
    const response = await axios.get(`${api.payment.getQR}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: 'text', 
    });
    console.log("getQR response:", response.data);
    
    return response.data;
  } catch (error) {
    console.error("Error getting QR code:", error);
    throw error;
  }
};

const uploadReceipt = async (file) => {
  try {
    const token = getAuthToken();
    const formData = new FormData();
    formData.append('image', file);

    const response = await axios.post(`${api.payment.uploadReceipt}`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    });

    console.log("uploadReceipt response:", response.data);
    return response.data; 
  } catch (error) {
    console.error("Error uploading receipt:", error);
    throw error;
  }
};

export default {
  getQR,
  uploadReceipt, 
};
