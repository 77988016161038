import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import { Autoplay, Navigation } from 'swiper/modules';
import Box from "@mui/material/Box";
import useAllData from '../../hooks/useAllData';
import api from '../../apiCollection';
import Error from '../core/Error';
import FlashDealCard from './FlashDealCard';
import { useTheme } from "@mui/material/styles";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import useMediaQuery from '@mui/material/useMediaQuery';

const baseCloudinaryURL = process.env.REACT_APP_CLOUDINARY_BASE_URL;
const bgImageURL = `${baseCloudinaryURL}/c_fill,q_auto:low,f_auto/v1722407339/vegingo/veg-doodle-bg_yprpif.png`;

export default function FlashDeals() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm')); 
  const { allData, loading, error } = useAllData(api.products.getAll);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <Error code={error.request.status} />;
  }

  return (
    <Box sx={{ position: 'relative'}}>
      <Swiper
        autoplay
        navigation={isSmallScreen ? false : { 
          nextEl: '.swiper-button-next-flash-deals',
          prevEl: '.swiper-button-prev-flash-deals',
        }}
        modules={[Navigation, Autoplay]}
        className="mySwiper3"
        style={{
          zIndex: 10,
          '--swiper-pagination-bullet-inactive-color': theme.palette.grey[500],
          '--swiper-pagination-bullet-inactive-opacity': '0.6',
          '--swiper-pagination-bullet-size': '15px',
          '--swiper-pagination-bullet-horizontal-gap': '6px',
          '--swiper-pagination-color': theme.palette.primary.main,
          border: "1px solid lightGray",
        }}
      >
        {allData.slice(0, 5).map((product) => (
          <SwiperSlide style={{ height: "100%" }} key={product.id}>
            <FlashDealCard product={product} />
          </SwiperSlide>
        ))}
      </Swiper>

      {!isSmallScreen && ( 
        <div style={{ position: "absolute", bottom: "-40px", right: "0px" }}>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: 5 }}>
            <div
              className="swiper-button-prev-flash-deals"
              style={{
                backgroundColor: theme.palette.primary.main,
                color: "white",
                borderRadius: "50%",
                width: "30px",
                height: "30px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
            >
              <ArrowBackIcon />
            </div>

            <div
              className="swiper-button-next-flash-deals"
              style={{
                backgroundColor: theme.palette.primary.main,
                color: "white",
                borderRadius: "50%",
                width: "30px",
                height: "30px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
            >
              <ArrowForwardIcon />
            </div>
          </div>
        </div>
      )}

      <Box sx={{ bgcolor: "red", textTransform: 'uppercase', position: "absolute", right: 0, top: 0, p: 1, zIndex: 10, fontSize: "0.8rem", color: "white", boxShadow: 2 }}>
        Hot Deals
      </Box>
    </Box>
  );
}
