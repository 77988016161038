import React, { useState } from 'react';
import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box"
import Chip from "@mui/material/Chip"
import Typography from '@mui/material/Typography';
import PageTitle from '../components/core/PageTitle';
import useApiFetch from '../hooks/useApiFetch';
import Loading from '../components/core/Loading';
import BlogCard from '../components/blog/BlogCard';
import { api} from '../apiCollection';
import Error from '../components/core/Error';

const tags = ['nutrition', 'health', 'wellness', 'gardening', 'organic', 'sustainability', 'environment', 'farming', 'technology', 'innovation', 'green'];

const Blog = () => {
    const allBlogsUrl = api.blog.getAll;
    const { data, loading, error } = useApiFetch(allBlogsUrl);
    const [selectedTags, setSelectedTags] = useState([]);

    const blogData = data?.data;

    if (loading) {
        return <Loading />;
    }

    if (error) {
        return <Error code={error.request.status}/>;
    }

    const handleTagClick = (tag) => {
        setSelectedTags(prevSelectedTags =>
            prevSelectedTags.includes(tag)
                ? prevSelectedTags.filter(t => t !== tag)
                : [...prevSelectedTags, tag]
        );
    };

    const filteredBlogs = selectedTags.length > 0
        ? blogData.filter(blog => selectedTags.every(tag => blog.tags.includes(tag)))
        : blogData;

    return (
        <Box minWidth="100%">
            <PageTitle title="Blogs" />
            <Container>
                <Grid container sx={{ my: 10 }} spacing={2}>
                    <Grid item xs={12} md={3}>
                        <Typography variant="h6" component="div" gutterBottom>
                            Tags
                        </Typography>
                        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                            {tags.map(tag => (
                                <Chip
                                    key={tag}
                                    label={tag}
                                    onClick={() => handleTagClick(tag)}
                                    color={selectedTags.includes(tag) ? 'primary' : 'default'}
                                    sx={{color:selectedTags.includes(tag) ? 'white' : 'default'}}
                                />
                            ))}
                        </div>
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <Grid columnSpacing={5} rowGap={5} container>
                            {filteredBlogs.map(blog => (
                                <Grid key={blog.id} item xs={12} md={6} lg={6}>
                                    <BlogCard blog={blog} />
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}

export default Blog;
