import React, { useEffect } from 'react';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import Container from "@mui/material/Container"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import PageTitle from '../components/core/PageTitle';
import WishlistCard from '../components/wishlist/WishlistCard';
import { useDispatch, useSelector } from 'react-redux';
import { fetchWishlistItems } from '../redux/slices/wishlistSlice';
import Loading from '../components/core/Loading';
import { Link } from 'react-router-dom';

const EmptyWishlist = () => {
  const theme = useTheme();
  return (
    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", minHeight: "80vh", gap: 2 }}>
      <Typography gutterBottom variant='h5'>
        Nothing Found in wishlist!
      </Typography>
      <ProductionQuantityLimitsIcon sx={{ fontSize: "6rem" }} />
      <Link to="/shop">
      <Button variant="contained" sx={{ bgcolor: theme.palette.primary.main, color: "white", borderRadius: "1.2rem" }}>Continue Shopping</Button>
      </Link>
    </Box>
  );
};

const ProductTable = () => {
  const itemCount = useSelector(state => state.wishlist.total);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchWishlistItems());
  }, [dispatch]);
  const isEmpty = itemCount === 0;
  const wishlist = useSelector((state) => state.wishlist);
  if (wishlist.status === "loading") {
    return <Loading />
  }
  console.log("Wishlist", wishlist);


  return (
    <>
      <PageTitle title="Wishlist" />
      {isEmpty ? (
        <EmptyWishlist />
      ) : (
        <Container sx={{ minHeight: "100vh" }} maxWidth="lg">
          <TableContainer sx={{ my: 10 }} component={Paper}>
            <Table>
              {!isSmallScreen && (
                <TableHead >
                  <TableRow>
                    <TableCell align="center">IMAGE</TableCell>
                    <TableCell align="center">PRODUCT</TableCell>
                    <TableCell align="center">PRICE</TableCell>
                    <TableCell align="center">PURCHASE</TableCell>
                    <TableCell align="center">REMOVE</TableCell>
                  </TableRow>
                </TableHead>
              )}
              <TableBody>
                {wishlist.products.map((item) => (
                  <WishlistCard key={item.id} product={item} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
      )}
    </>
  );
};

export default ProductTable;
