import React, { useEffect, useState, useCallback } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from "@mui/material/Box";
import ProductCard from '../product/ProductCard';
import Header from './Header';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Filters from './Filters';

const Collection = ({ data }) => {
  const [sortOption, setSortOption] = useState('priceLowHigh');
  const [view, setView] = useState("grid");

  const [selectedFilters, setSelectedFilters] = useState({
    is_in_stock: [],
    price: [],
    category_id: [],
    moreFilters: [],
    brand: [],
    size: []
  });

  const theme = useTheme();
  const [sortedData, setSortedData] = useState([]);
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleSort = useCallback((filteredData) => {
    let sortedData = [...filteredData];
    sortedData.sort((a, b) => {
      if (sortOption === 'priceLowHigh') {
        return a.price - b.price;
      }
      if (sortOption === 'priceHighLow') {
        return b.price - a.price;
      }
      return 0;
    });
    return sortedData;
  }, [sortOption]);

  const handleFilters = useCallback((products) => {
    return products.filter(product => {
      return Object.keys(selectedFilters).every(filterKey => {
        if (selectedFilters[filterKey].length === 0) {
          return true;
        }
        if (filterKey === 'price') {
          return selectedFilters.price.some(priceRange => {
            if (priceRange === 'under20') return (product.discounted_price?product.discounted_price < 20:product.price < 20);
            if (priceRange === '21to50') return (product.discounted_price ? product.discounted_price >= 21 && product.discounted_price <= 50:product.price >= 21 && product.price <= 50);
            if (priceRange === '51to100') return (product.discounted_price?product.discounted_price >= 51 && product.discounted_price <= 100 : product.price >= 51 && product.price <= 100);
            if (priceRange === '101to200') return (product.discounted_price ? product.discounted_price >= 101 && product.discounted_price <= 200 : product.price >= 101 && product.price<= 200);
            if (priceRange === '201to500') return (product.discounted_price?product.discounted_price >= 201 && product.discounted_price <= 500:product.price >= 201 && product.price <= 500);
            if (priceRange === 'over500') return (product.discounted_price?product.discounted_price>500:product.price > 500);
            return false;
          });
        }
        return selectedFilters[filterKey].includes(product[filterKey]);
      });
    });
  }, [selectedFilters]);

  useEffect(() => {
    let filteredData = handleFilters(data);
    setSortedData(handleSort(filteredData));
  }, [sortOption, data, selectedFilters, handleFilters, handleSort]);

  return (
    <Box>
      <Container maxWidth="lg">
        <Grid container spacing={2} sx={{ my:{xs:2,md:10} }}>
          {!isMediumScreen && (
            <Grid item xs={12} md={3}>
              <Box sx={{ borderRight: '1px solid #ddd', paddingRight: 2 }}>
                <Filters selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilters} />
              </Box>
            </Grid>
          )}
          <Grid item xs={12} md={9}>
            <Header view={view} setView={setView} count={sortedData.length} sortOption={sortOption} setSortOption={setSortOption} selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilters} />
            <Grid container spacing={isSmallScreen ? 2 : 5}>
              {sortedData.map(product => (
                <Grid mb={isSmallScreen ? 3 : 0} item xs={view === "grid" ? 6 : 12} sm={view === "grid" ? 6 : 12} md={view === "grid" ? 4 : 12} lg={view === "grid" ? 4 : 12} key={product.id}>
                  <ProductCard productData={product} flexDirection={view === "grid" ? "column" : "row"} button={true} />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Collection;
